import { Loading } from "src/components/utils/Loading";
import { useIngredientContext } from "../ExistingIngredientContext";
import { NoSsq } from "./IncompleteData/NoSsq";
import { NoIngredientReference } from "./IncompleteData/NoIngRef";
import { NoSupplier } from "./IncompleteData/NoSupplier";
import { IngredientReferenceQuestionnaire } from "./IngredientReferenceQuestionnaire";

export const Questionnaire = () => {
    const { ingredient, isLoading, ingredientReference, ssq, posts } = useIngredientContext();

    if (isLoading === true) {
        return <Loading />;
    }

    if (ingredient === undefined) {
        // Error
        return;
    }

    if (ingredient.ingredientReferenceId === undefined) {
        return <NoIngredientReference />;
    }

    const ssqId = ingredientReference?.ssq?.id;
    if (ssqId === undefined) {
        return <NoSsq />;
    }

    const ingredientSuppliers = ingredient.ingredientSuppliers ?? [];
    if (ingredientSuppliers.length === 0) {
        return <NoSupplier />;
    }

    return (
        <IngredientReferenceQuestionnaire
            ingredient={ingredient}
            ssq={ssq}
            posts={posts}
            ingSuppliers={ingredientSuppliers}
        />
    );
};
