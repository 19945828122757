import { useState, Dispatch, SetStateAction, useEffect } from "react";
import { Box, Container, Typography, Divider, Stack, Checkbox, Button } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useUpdateScoreType } from "../../api/client-api/scoreType/useUpdateScoreType";
import { Score } from "src/types";
import { ColumnsService } from "src/utils/ColumnsService";
import { getScoreIdsByType } from "src/utils/scores";
import { ScoreType, useGetAllScoreType } from "src/api/client-api/scoreType/useGetAllScoreType";
import { useGetAllScore } from "src/api/sure-api/score/useGetAllScore";
import { useQueryClient } from "react-query";

export const Scores = () => {
    const { t } = useTranslation();

    const { scores } = useGetAllScore();
    const { scoreTypes } = useGetAllScoreType();

    const companyScoresIds = getScoreIdsByType("company", scoreTypes);
    const productsScoresIds = getScoreIdsByType("product", scoreTypes);
    const ingredientsScoresIds = getScoreIdsByType("ingredient", scoreTypes);

    if (scores === undefined) return;
    if (scoreTypes === undefined) return;

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography component="h1" variant="h2">
                        {t("Scores affichés")}
                    </Typography>
                </Stack>
            </Stack>
            <Box sx={{ py: 2 }}></Box>
            <Divider />
            <ScoresGrid
                scores={scores}
                companyScoresIds={companyScoresIds}
                productsScoresIds={productsScoresIds}
                ingredientsScoresIds={ingredientsScoresIds}
            />
        </Container>
    );
};

type ScoresGridProps = {
    scores: Score[];
    companyScoresIds?: number[];
    productsScoresIds?: number[];
    ingredientsScoresIds?: number[];
};
export const ScoresGrid = (props: ScoresGridProps) => {
    const { t } = useTranslation();

    const {
        scores,
        companyScoresIds: initialOneSupplierScoresIds = [],
        productsScoresIds: initialProductsScoresIds = [],
        ingredientsScoresIds: initialIngredientsScoresIds = [],
    } = props;

    const [companyScoresIds, setOneSupplierScoresIds] = useState<number[]>(initialOneSupplierScoresIds);
    const [productsScoresIds, setProductsScoresIds] = useState<number[]>(initialProductsScoresIds);
    const [ingredientsScoresIds, setIngredientsScoresIds] = useState<number[]>(initialIngredientsScoresIds);

    // Start special block
    // Somehow this is necessary to always have an update to date grid with the former way this page is built.
    // This is a hotfix waiting for the bigger refacto of the settings
    useEffect(() => {
        setOneSupplierScoresIds(initialOneSupplierScoresIds);
    }, [initialOneSupplierScoresIds]);

    useEffect(() => {
        setProductsScoresIds(initialProductsScoresIds);
    }, [initialProductsScoresIds]);

    useEffect(() => {
        setIngredientsScoresIds(initialIngredientsScoresIds);
    }, [initialIngredientsScoresIds]);
    // End special block

    const updateScoreType = useUpdateScoreType();
    const [loading, setLoading] = useState(false);
    const query = useQueryClient();

    const toggleScore = (scoresGetter: number[], scoresSetter: Dispatch<SetStateAction<number[]>>, score: number) => {
        if (!scoresGetter.includes(score)) {
            scoresSetter([...scoresGetter, score]);

            return;
        }

        const filteredScores = scoresGetter.filter((id) => id !== score);
        scoresSetter(filteredScores);
    };

    const getScoreIds = (type: ScoreType["type"]): number[] => {
        switch (type) {
            case "product":
                return productsScoresIds;
            case "ingredient":
                return ingredientsScoresIds;
            case "company":
                return companyScoresIds;
        }
    };

    const onSubmit = (type: ScoreType["type"]) => {
        setLoading(true);

        const scoreIds = getScoreIds(type);

        updateScoreType.mutate(
            { type, scoreIds },
            {
                onSuccess: () => {
                    setLoading(false);
                    ColumnsService.updateScoresColumnsSettings(type, scoreIds);
                    location.reload();
                    // query.refetchQueries([getAllScoreKey, getAllScoreTypeKey]);
                },
            },
        );
    };

    const columns: GridColDef[] = [
        {
            field: "scores",
            headerName: t("Scores"),
            flex: 2,
            renderCell: (params) => (
                <Typography variant="h4" sx={{ fontWeight: 700, whiteSpace: "break-spaces" }}>
                    {params.row.title}
                </Typography>
            ),
        },
        {
            field: "company",
            headerName: t("Entreprise"),
            flex: 2,
            renderCell: (params) => (
                <Checkbox
                    checked={companyScoresIds.includes(params.row.id)}
                    onChange={() => {
                        toggleScore(companyScoresIds, setOneSupplierScoresIds, params.row.id);
                    }}
                />
            ),
            sortable: false,
        },
        {
            field: "products",
            headerName: t("Produits"),
            flex: 2,
            renderCell: (params) => (
                <Checkbox
                    checked={productsScoresIds.includes(params.row.id)}
                    onChange={() => {
                        toggleScore(productsScoresIds, setProductsScoresIds, params.row.id);
                    }}
                />
            ),
            sortable: false,
        },
        {
            field: "ingredients",
            headerName: t("Ingredients"),
            flex: 2,
            renderCell: (params) => (
                <Checkbox
                    checked={ingredientsScoresIds.includes(params.row.id)}
                    onChange={() => {
                        toggleScore(ingredientsScoresIds, setIngredientsScoresIds, params.row.id);
                    }}
                />
            ),
            sortable: false,
        },
    ];

    const height = scores.length ? `${scores ? scores.length * 100 : 0}px !important` : "100%";

    return (
        <>
            <DataGrid
                autoHeight
                rowHeight={80}
                rows={scores}
                columns={columns}
                disableColumnMenu={true}
                loading={loading}
                sx={{
                    border: "none",
                    backgroundColor: "transparent",
                    "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
                        marginBottom: "2px",
                        border: 0,
                        backgroundColor: "transparent",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                        fontSize: "12px",
                        lineHeight: "24px",
                        color: "#797A77",
                    },
                    "& .MuiDataGrid-iconSeparator": {
                        display: "none",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-row": {
                        backgroundColor: "white",
                        borderRadius: "15px",
                        marginTop: "10px",
                        marginBottom: "10px",
                    },
                    "& .MuiDataGrid-row.Mui-selected": {
                        background: " #FFFFFF",
                        border: 0,
                    },
                    "& .MuiDataGrid-cell": {
                        border: 0,
                        paddingLeft: "20px",
                        outline: "none !important",
                        // fontWeight: "bold",
                        // fontSize: "16px",
                        // lineHeight: "24px",
                        // color: "#2B2727",
                    },
                    "& .MuiDataGrid-footerContainer": {
                        display: "none",
                    },
                    "& .MuiDataGrid-virtualScrollerContent": {
                        height: height,
                        boxSizing: "content-box",
                    },
                    "& .MuiDataGrid-overlayWrapperInner": {
                        height: height,
                    },
                }}
            />

            {scores && (
                <Stack direction="row" sx={{ marginTop: "1rem" }}>
                    <Box width="25%"></Box>
                    <Box width="25%">
                        <Button variant="primary" type="submit" onClick={() => onSubmit("company")} disabled={loading}>
                            {t("Enregistrer")}
                        </Button>
                    </Box>
                    <Box width="25%">
                        <Button variant="primary" type="submit" onClick={() => onSubmit("product")} disabled={loading}>
                            {t("Enregistrer")}
                        </Button>
                    </Box>
                    <Box width="25%">
                        <Button
                            variant="primary"
                            type="submit"
                            onClick={() => onSubmit("ingredient")}
                            disabled={loading}
                        >
                            {t("Enregistrer")}
                        </Button>
                    </Box>
                </Stack>
            )}
        </>
    );
};
