import { Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DraggableColumn } from "./DraggableColumn";
import { BigPopoverLayout } from "@foodpilot/foods";
import { ColumnConfig } from "src/utils/useGridColumns";

export type ColumnPositionPopoverProps = {
    onClose: () => void;
    columnConfig: ColumnConfig;
    columnOrder: string[];
    setColumnOrder: (values: string[]) => void;
    toggleVisibility: (columnName: string, visible: boolean) => void;
};

export const ColumnPositionPopover = (props: ColumnPositionPopoverProps) => {
    const { columnConfig, columnOrder, setColumnOrder, toggleVisibility } = props;
    const { t } = useTranslation();

    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const prevColumns: string[] = columnOrder;
            let newColumns: string[] = prevColumns.toSpliced(dragIndex, 1);
            newColumns = newColumns.toSpliced(hoverIndex, 0, prevColumns[dragIndex]);
            setColumnOrder(newColumns);
        },
        [columnOrder, setColumnOrder],
    );

    const renderColumn = useCallback(
        (columnName: string, index: number) => {
            //filtrage des colonnes internes a MRT
            if (!columnName.includes("mrt")) {
                const columnConf = columnConfig[columnName];
                return (
                    <DraggableColumn
                        key={columnName}
                        index={index}
                        columnName={columnName}
                        visibility={columnConf.visible}
                        moveColumn={moveColumn}
                        header={columnConf.displayName}
                        toggleVisibility={toggleVisibility}
                    />
                );
            }
        },
        [moveColumn, columnConfig],
    );

    return (
        <BigPopoverLayout headerText={t("Modifier les colonnes affichées")} onClose={props.onClose}>
            <Typography
                variant="body-medium"
                component="div"
                sx={{
                    marginBottom: "24px",
                    display: "block",
                }}
            >
                {t(
                    "Sélectionnez les informations que vous souhaitez voir apparaître dnas les colonnes du tableau et définissez leur ordre (plus l'information est haute dans la liste, plus elle sera située a gauche du tableau):",
                )}
            </Typography>
            <Stack useFlexGap gap="2px">
                {columnOrder.map((columnName, index) => renderColumn(columnName, index))}
            </Stack>
        </BigPopoverLayout>
    );
};
