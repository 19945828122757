import { z } from "zod";

export const EmptyScoreSchema = z.record(z.never());

export const MainScoreSchema = z.object({
    score: z.number(),
    unit: z.string(),
});

export const ScoreExtensionSchema = z.object({
    label: z.string(),
    default_display: z.boolean(),
});

export const ScoreVariationSchema = z.object({
    id: z.number(),
    label: z.string(),
    default_display: z.boolean(),
});

export const ScoreDimensionBaseValueSchema = z.object({
    score: z.number(),
    label: z.string(),
});

export const ScoreDimensionValueSchema: z.ZodType<ScoreDimensionValue> = ScoreDimensionBaseValueSchema.extend({
    dimensions: z.array(z.lazy(() => ScoreDimensionSchema)).optional(),
});

export const ScoreDimensionSchema = z.object({
    label: z.string(),
    values: z.record(z.string(), ScoreDimensionValueSchema),
});

export const ScoreMetadataSchema = z.object({
    variations: ScoreVariationSchema.array(),
    dimensions: ScoreDimensionSchema.array(),
});

export const ScoreSchema = MainScoreSchema.merge(ScoreExtensionSchema).merge(ScoreMetadataSchema);

export const MainScoresSchema = z.record(z.string(), MainScoreSchema.or(EmptyScoreSchema));
export const ScoresSchema = z.record(z.string(), ScoreSchema.or(EmptyScoreSchema));

export type EmptyScore = z.infer<typeof EmptyScoreSchema>;
export type MainScore = z.infer<typeof MainScoreSchema>;
export type ScoreExtension = z.infer<typeof ScoreExtensionSchema>;
export type ScoreVariation = z.infer<typeof ScoreVariationSchema>;
export type ScoreDimensionBaseValue = z.infer<typeof ScoreDimensionBaseValueSchema>;

export type ScoreDimensionValue = ScoreDimensionBaseValue & {
    dimensions?: ScoreDimension[];
};

export type ScoreDimension = z.infer<typeof ScoreDimensionSchema>;
export type ScoreMetadata = z.infer<typeof ScoreMetadataSchema>;
export type Score = z.infer<typeof ScoreSchema>;

export type MainScores = z.infer<typeof MainScoresSchema>;
export type Scores = z.infer<typeof ScoresSchema>;

export const isScoreEmpty = (score: Score | MainScore | EmptyScore): score is EmptyScore => {
    return !("score" in score);
};
