import { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
    Box,
    Container,
    Typography,
    Button,
    Divider,
    Stack,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import {
    DataGrid,
    GridRowsProp,
    GridColDef,
    GridRowModesModel,
    GridRowModes,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowId,
} from "@mui/x-data-grid";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { useTranslation } from "react-i18next";
import { useAllUsers } from "src/api/client-api/user/useAllUsers.ts";
import { useDeleteUser } from "src/api/client-api/user/useDeleteUser.ts";
import { useSnackbar } from "src/utils/useSnackbar.tsx";
import { LocalStorage } from "src/services/LocalStorage";
import { URLHandler } from "src/services/URLHandler";
import { useDelayedSearch } from "src/utils/useDelayedSearch";
import { TextField } from "@foodpilot/foods";

export const UsersList = () => {
    const navigate = useNavigate();

    const [paginationModel, setPaginationModel] = useState<{ page: number; pageSize: number }>({
        page: 0,
        pageSize: 20,
    });
    const { search, delayedSearch, setSearch } = useDelayedSearch();
    const { search: searchEmail, delayedSearch: delayedSearchEmail, setSearch: setSearchEmail } = useDelayedSearch();
    const {
        search: searchCompanies,
        delayedSearch: delayedSearchCompanies,
        setSearch: setSearchCompanies,
    } = useDelayedSearch();
    const { users, isLoading, totalItems } = useAllUsers({
        page: paginationModel.page + 1,
        itemsPerPage: paginationModel.pageSize,
        search: delayedSearch,
        email: delayedSearchEmail,
        company: delayedSearchCompanies,
    });

    const [rows, setRows] = useState<GridRowsProp>([]);

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [displayDialog, setDisplayDialog] = useState(false);
    const [idToDelete, setIdToDelete] = useState<number | undefined>(undefined);

    const deleteUser = useDeleteUser();
    const snackbarr = useSnackbar();

    const { t } = useTranslation();

    type UsersListRows = {
        id: number;
        col1: string;
        col3: string;
        col5: string[];
        col6?: string;
        actions: { index: number };
    };

    useEffect(() => {
        if (!users) return;

        const localRows: UsersListRows[] = [];
        users.forEach((users, index) => {
            localRows.push({
                id: users.id,
                col1: `${users.firstname} ${users.lastname}`,
                col3: users.email,
                col5: users.companies.map((company) => company.name),
                col6: users.roles.join(", "),
                actions: { index },
            });
        });
        setRows(localRows);
    }, [users]);

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleImpersonateClick = (id: GridRowId) => () => {
        if (!users) return;

        const selectedUser = users.find((user) => user.id === id);

        if (selectedUser) {
            LocalStorage.setItem("connectedAs", selectedUser.email);
            LocalStorage.deleteItem("company");
            window.location.href = URLHandler.home();
        }
    };

    const handleDeleteClick = (id: number | string) => () => {
        if (typeof id === "string") return;

        setIdToDelete(id);
        setDisplayDialog(true);
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const renderConfirmDialog = () => {
        return (
            <Dialog maxWidth="xs" open={displayDialog}>
                <DialogTitle>Êtes-vous sûr ?</DialogTitle>
                <DialogContent dividers>
                    {t("Cliquez sur 'oui' pour supprimer définitivement l'utilisateur")}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDisplayDialog(false);
                        }}
                    >
                        {t("Non")}
                    </Button>
                    <Button
                        onClick={() => {
                            if (idToDelete) {
                                deleteUser.mutate(idToDelete, {
                                    onSuccess: () => {
                                        setRows(rows.filter((row) => row.id !== idToDelete));
                                    },
                                    onError: () => {
                                        snackbarr.forceClose();
                                        snackbarr.setSnackbarProps({
                                            type: "error",
                                            message: t("unknown-error"),
                                        });
                                    },
                                });
                            }
                            setDisplayDialog(false);
                        }}
                    >
                        {t("Oui")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const nameColumnLabel = `${t("Prenom")} / ${t("Nom")}`;
    const columns: GridColDef[] = [
        {
            field: "col1",
            headerName: nameColumnLabel,
            flex: 1,
            renderCell: (params) => (
                <Typography variant="body1" sx={{ whiteSpace: "break-spaces" }}>
                    {params.value}
                </Typography>
            ),
            filterable: false,
            editable: false,
        },
        {
            field: "col3",
            headerName: t("Email"),
            flex: 1,
            renderCell: (params) => (
                <Typography variant="body1" sx={{ whiteSpace: "break-spaces" }}>
                    {params.value}
                </Typography>
            ),
            filterable: false,
            editable: false,
        },
        {
            field: "col5",
            headerName: t("Société"),
            flex: 1,
            filterable: false,
            editable: false,
        },
        {
            field: "col6",
            headerName: t("Roles"),
            flex: 1,
            renderCell: (params) => (
                <Typography variant="body1" sx={{ whiteSpace: "break-spaces" }}>
                    {params.value}
                </Typography>
            ),
            filterable: false,
            editable: false,
        },
        {
            field: "actions",
            type: "actions",
            headerName: t("Actions"),
            width: 200,
            cellClassName: "actions",
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} key={id} />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                            key={id}
                        />,
                    ];
                }

                return [
                    <RouterLink to={URLHandler.settings.users.view(Number(id))} key={id}>
                        <GridActionsCellItem sx={{ color: "#000" }} icon={<ArrowOutwardIcon />} label="Open page" />
                    </RouterLink>,
                    <GridActionsCellItem
                        icon={<SupervisedUserCircleIcon />}
                        label="Impersonate"
                        onClick={handleImpersonateClick(id)}
                        color="inherit"
                        key={id}
                    />,
                    <GridActionsCellItem
                        icon={
                            <IconButton component={RouterLink} to={URLHandler.settings.users.edit(Number(id))}>
                                <EditIcon />
                            </IconButton>
                        }
                        label="Edit"
                        className="textPrimary"
                        color="inherit"
                        key={id}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id.valueOf())}
                        color="inherit"
                        key={id}
                    />,
                ];
            },
        },
    ];

    function EditToolbar() {
        const handleClick = () => {
            navigate(URLHandler.settings.users.create());
        };

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    {t("Créer un utilisateur")}
                </Button>
            </GridToolbarContainer>
        );
    }

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Stack direction="column" spacing={2} alignItems="center">
                    <Typography component="h1" variant="h2">
                        {t("Utilisateurs")}
                    </Typography>
                </Stack>
            </Stack>
            <Box sx={{ py: 2 }}></Box>
            <Box sx={{ display: "flex" }}>
                <TextField
                    title={`${nameColumnLabel} - Filter`}
                    placeholder={""}
                    value={search}
                    onChange={(event) => {
                        const newSearch = event.target.value;
                        setSearch(newSearch);
                    }}
                />
                <TextField
                    title={`${t("Email")} - Filter`}
                    placeholder={""}
                    value={searchEmail}
                    onChange={(event) => {
                        const newSearch = event.target.value;
                        setSearchEmail(newSearch);
                    }}
                />
                <TextField
                    title={`${t("Société")} - Filter`}
                    placeholder={""}
                    value={searchCompanies}
                    onChange={(event) => {
                        const newSearch = event.target.value;
                        setSearchCompanies(newSearch);
                    }}
                />
                <Button
                    variant="text"
                    onClick={() => {
                        setSearch("");
                        setSearchEmail("");
                        setSearchCompanies("");
                    }}
                >
                    {t("Effacer la recherche")}
                </Button>
            </Box>
            <Divider />
            <Box sx={{ py: 2 }}></Box>
            {renderConfirmDialog()}
            <DataGrid
                loading={isLoading}
                autoHeight
                rowHeight={115}
                rows={rows}
                columns={columns}
                editMode="row"
                rowCount={totalItems}
                rowModesModel={rowModesModel}
                onPaginationModelChange={(model, details) => {
                    setPaginationModel(model);
                }}
                paginationMode="server"
                paginationModel={paginationModel}
                pageSizeOptions={[paginationModel.pageSize]}
                onRowModesModelChange={handleRowModesModelChange}
                slots={{
                    toolbar: EditToolbar,
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
                sx={getGridStyle(paginationModel.pageSize)}
            />
        </Container>
    );
};

const getGridStyle = (length: number) => {
    return {
        border: "none",
        backgroundColor: "transparent",
        "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
            marginBottom: "2px",
            border: 0,
            backgroundColor: "transparent",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
            fontSize: "12px",
            lineHeight: "24px",
            color: "#797A77",
        },
        "& .MuiDataGrid-iconSeparator": {
            display: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-row": {
            backgroundColor: "white",
            borderRadius: "15px",
            marginTop: "10px",
        },
        "& .MuiDataGrid-row.Mui-selected": {
            background: " #FFFFFF",
            border: 0,
        },
        "& .MuiDataGrid-cell": {
            border: 0,
            outline: "none !important",
        },
        "& .MuiDataGrid-virtualScrollerContent": {
            paddingBottom: `${length * 10 + 10}px`,
            height: `fit-content`,
            boxSizing: "content-box",
        },
    };
};
