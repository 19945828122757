import { Stack, Typography } from "@mui/material";
import { Ingredient, IngredientSupplier } from "src/api/client-api/ingredients/useGetOneIngredient";
import { Post } from "src/api/client-api/post/postSchema";
import { useTranslation } from "react-i18next";
import { QuestionnairesList } from "src/components/QuestionnairesList/QuestionnairesList";
import { ScoresSection } from "src/components/QuestionnairesList/Scores/ScoresSection";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { Loading } from "src/components/utils/Loading";
import { ButtonTabs } from "@foodpilot/foods";
import { NoPostSupplier } from "./IncompleteData/NoPostSupplier";
import { getHeadingsCompletions, getVisibleHeadings } from "src/utils/ssq";
import { Ssq, Heading } from "src/api/sure-api/ssq/useGetOneSsq";
import { getScoreIdsByType } from "src/utils/scores";
import { ScoreProvider } from "src/context/ScoreContext";
import { useIngredientContext } from "../ExistingIngredientContext";
import { URLHandler } from "src/services/URLHandler";

type IngredientReferenceQuestionnaireProps = {
    ingredient: Ingredient;
    ingSuppliers: IngredientSupplier[];
    ssq: Ssq | undefined;
    posts: Post[] | undefined;
};
export const IngredientReferenceQuestionnaire = (props: IngredientReferenceQuestionnaireProps) => {
    const { scoreTypes, selectedCompany, isUserOfTypeSupplier } = useFoodpilotContext();
    const { t } = useTranslation();
    const { activeSupplierId, setActiveSupplierId } = useIngredientContext();

    const ingredientsScoresIds = getScoreIdsByType("ingredient", scoreTypes);

    const { ingredient, ssq, posts, ingSuppliers } = props;

    if (ssq === undefined) {
        return <Loading />;
    }

    if (posts === undefined) {
        return <Loading />;
    }

    if (selectedCompany === null) return;

    const firstSupplierId = ingSuppliers[0]?.supplier.id;
    const desiredSupplierId = activeSupplierId || firstSupplierId;

    const selectedSupplier = ingSuppliers.find((supplier) => supplier.supplier.id === desiredSupplierId);
    const selectedPost = posts.find((item) => item.company?.id === desiredSupplierId);

    const allSuppliers = ingSuppliers.map((value) => {
        // I am ashamed, but shame doesn't kill
        const currentPost = posts.find((item) => item.company?.id === value.supplier.id);
        // Those 2 lines are because it's 7pm, and I'm lazy to refactor :)
        // Those 2 functions are present in QuestionnairesList, and need to be placed at only one point !
        const headings: Heading[] = ssq ? getVisibleHeadings(ssq) : [];
        const headingsCompletions: number[] = getHeadingsCompletions(headings, currentPost);

        const completions: number = headingsCompletions.reduce((a: number, b: number) => a + b, 0);
        const total = headingsCompletions.length ? Math.round(completions / headingsCompletions.length) : 0;

        return `${value.supplier.name} - ${total} %`;
    });

    const isSupplier = isUserOfTypeSupplier();
    const questionnaireTitle = isSupplier ? t("ssq.ingredient.mainTitle") : t("ssq.ingredient.supplierTitle");
    return (
        <Stack gap="30px">
            <Typography variant="h2">{questionnaireTitle}</Typography>
            {isSupplier === false && (
                <ButtonTabs
                    choices={allSuppliers}
                    defaultIndex={selectedSupplier && ingSuppliers.indexOf(selectedSupplier)}
                    onClick={(index: number) => {
                        setActiveSupplierId(ingSuppliers[index].supplier.id);
                    }}
                />
            )}
            <Stack gap="28px">
                {selectedPost === undefined && isSupplier === false ?
                    <NoPostSupplier />
                :   <QuestionnairesList
                        ssq={ssq}
                        post={selectedPost}
                        isSsqLoading={false}
                        isPostLoading={false}
                        completeQuestionnaireUrl={(heading: Heading) => {
                            const url = URLHandler.ingredient.ssqHeading(selectedCompany.id, ingredient.id, heading.id);
                            return url;
                        }}
                        scoresSection={
                            <ScoreProvider type="ingredient" formattedScores={selectedPost?.formattedScores}>
                                <ScoresSection
                                    post={selectedPost}
                                    scoresIds={ingredientsScoresIds}
                                    headings={ssq.headings}
                                />
                            </ScoreProvider>
                        }
                        canEdit={isSupplier && ingredient.canModify}
                    />
                }
            </Stack>
        </Stack>
    );
};
