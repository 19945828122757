import { Stack, useTheme, CircularProgress, Button, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { FormInput } from "src/components/FormInput";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { removeInexwebInfo } from "src/utils/token";
import { useApplicationParameters } from "src/api/client-api/appParams/useApplicationParameters";
import { WhiteBox } from "@foodpilot/foods";
import { z } from "zod";
import { loginUserFn } from "src/api/authApi";

export const loginSchema = z.object({
    email: z.string().min(1, "Email address is required").email("Email Address is invalid"),
    password: z
        .string()
        .min(1, "Password is required")
        // .min(8, "Password must be more than 8 characters")
        .max(32, "Password must be less than 32 characters"),
});
export type LoginInput = z.infer<typeof loginSchema>;

export const Login = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [isError, setError] = useState(false);
    const navigate = useNavigate();
    const { applicationParameters } = useApplicationParameters();

    const methods = useForm<LoginInput>({
        defaultValues: {
            email: "",
            password: "",
        },
        resolver: zodResolver(loginSchema),
    });

    // When the user logout, he is redirected here
    // When doing the action at logout the router isn't working
    // because it does use those credentials
    // The action is also performed when app is mounted
    if (applicationParameters?.mode === "inexweb") removeInexwebInfo();

    const mutateLogin = useMutation({
        mutationFn: (notLoggedUser: LoginInput) => {
            return loginUserFn(notLoggedUser);
        },
        onSuccess: (response) => {
            navigate("/");
        },
        onError: (err, _variables, _context) => {
            setError(true);
            console.error("There was an error during Connection :", err);
        },
    });

    return (
        <FormProvider {...methods}>
            <WhiteBox
                padding="40px"
                paddingTop="32px"
                boxShadow="0 2px 8px 0 rgba(42, 43, 39, 0.04)"
                position="relative"
            >
                {mutateLogin.isLoading && (
                    <Stack
                        sx={{
                            background: "rgba(255, 255, 255, 0.5)",
                            position: "absolute",
                            inset: "calc(40px / 2)",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 1,
                        }}
                    >
                        <CircularProgress />
                    </Stack>
                )}
                <Stack
                    component="form"
                    onSubmit={methods.handleSubmit(
                        (data, _event) => {
                            setError(false);
                            mutateLogin.mutate(data);
                        },
                        (_errors, _event) => {
                            // Here are the errors from the argument formatting.
                            // We may want to display the Errors using the zod Schema
                            // at some point;
                            setError(true);
                        },
                    )}
                    noValidate
                    autoComplete="off"
                    gap="36px"
                >
                    <LoginHeader />
                    {isError && (
                        <Typography variant="h4" align="center" color="error">
                            {t("incorrect email or password")}
                        </Typography>
                    )}
                    <LoginInputs values={methods.getValues()} />
                </Stack>
            </WhiteBox>
        </FormProvider>
    );
};

const LoginHeader = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Stack justifyContent="center" alignItems="center" gap="16px">
            <Typography variant="h2" align="center">
                {t("login-title")}
            </Typography>
            <Typography variant="body" align="center" sx={{ color: theme.custom.grey[2000] }}>
                {t("login-info")}
            </Typography>
        </Stack>
    );
};

type LoginInputsProps = {
    values: LoginInput;
};
const LoginInputs = (props: LoginInputsProps) => {
    const { t } = useTranslation();
    const { values } = props;

    return (
        <Stack gap="24px">
            <FormInput
                type="email"
                name="email"
                label={t("Adresse email")}
                inputProps={{
                    "data-testid": "test-mailId",
                }}
            />

            <Stack>
                <FormInput
                    type="password"
                    name="password"
                    label={t("Mot de passe")}
                    inputProps={{
                        "data-testid": "test-pwdId",
                    }}
                />
                <Button
                    variant="text"
                    component={Link}
                    to="/forgot-password"
                    sx={{
                        width: "100%",
                        height: "auto",
                        justifyContent: "left",
                    }}
                >
                    {t("forgotten-password")}
                </Button>
            </Stack>

            <Button
                variant="primary"
                sx={{ width: "100%", fontWeight: 700 }}
                type="submit"
                disabled={!values.email || !values.password}
            >
                {t("login-connect")}
            </Button>
        </Stack>
    );
};
