import { createMRTColumnHelper } from "material-react-table";
import { Stack, Typography, useTheme, CircularProgress } from "@mui/material";
import { Heading } from "src/api/sure-api/ssq/useGetOneSsq";
import { Badge, LinearProgress } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";

export const useQuestionnairesColumns = () => {
    const columnHelper = createMRTColumnHelper();
    const { t } = useTranslation();

    return [
        columnHelper.accessor("heading", {
            header: t("Thématiques"),
            Cell: (value) => {
                const heading: Heading = value.cell.getValue();

                return (
                    <Stack direction="row" gap="12px" alignItems="center">
                        <Badge>{value.row.index + 1}</Badge>

                        <Stack gap="4px">
                            <Typography variant="h5">{heading.title}</Typography>
                        </Stack>
                    </Stack>
                );
            },
        }),
        columnHelper.accessor("completion", {
            header: "Complétion",
            muiTableHeadCellProps: {
                align: "center",
            },
            Cell: (value) => {
                const progress = value.cell.getValue();

                return progress === undefined ? <CircularProgress size="18px" /> : <LinearProgress value={progress} />;
            },
        }),
    ];
};
