import { Fragment } from "react";
import { Button, Typography, styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import ClickMe from "src/assets/icons/click-me.svg?react";
import { RoundNumberInput } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";
import { useMaterialReference } from "src/api/sure-api/usePackagingMaterialSet";
import { t } from "i18next";
import { ProductFormPackagingMaterial, ProductFormProductPackaging } from "../../../ProductForm.types";

const BaseCell = styled(Grid)(() => ({
    display: "flex",
    alignItems: "center",
    textAlign: "left",

    minHeight: "25px",
}));

const BodyCell = styled(BaseCell)(() => ({
    minHeight: "50px",
}));

const MaterialHeader = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <BaseCell item xs={4}>
                <Typography variant="h5">{t("Matériau")} :</Typography>
            </BaseCell>
            <BaseCell item xs={4}>
                <Typography variant="h5">{t("Poids")} :</Typography>
            </BaseCell>
            <BaseCell item xs={4}>
                <Typography variant="h5">{t("Part de matériau recyclée")} :</Typography>
            </BaseCell>
        </Fragment>
    );
};

type MaterialBodyProps = {
    packagingMaterial: ProductFormPackagingMaterial;
    setPackagingMaterial: (newMaterial: ProductFormPackagingMaterial) => void;
};
const MaterialBody = (props: MaterialBodyProps): JSX.Element => {
    const { packagingMaterial, setPackagingMaterial } = props;

    const { materialsReference } = useMaterialReference();
    const foundset = materialsReference.find((matRef) => matRef.id === packagingMaterial.packagingMaterialSetId);

    return (
        <Fragment>
            <BodyCell item xs={4}>
                {/* The padding creates an effect of "nested list" */}
                <Typography variant="big-bold" sx={{ paddingLeft: "12px" }}>
                    {foundset?.name ?? t("Non trouvé")}
                </Typography>
            </BodyCell>
            <BodyCell item xs={4}>
                <RoundNumberInput
                    value={packagingMaterial.weight ?? ""}
                    placeholder={"0"}
                    onChange={(e) => {
                        const newWeight = Number(e.target.value);
                        if (e.target.value !== newWeight.toString()) {
                            // Invalid input !
                            setPackagingMaterial({
                                ...packagingMaterial,
                                weight: null,
                            });
                            return;
                        }
                        setPackagingMaterial({
                            ...packagingMaterial,
                            weight: newWeight,
                        });
                    }}
                    unit={"g"}
                    sx={{ minWidth: "150px", minHeight: "40px" }}
                />
            </BodyCell>
            <BodyCell item xs={4}>
                <RoundNumberInput
                    value={packagingMaterial.proportion ?? ""}
                    placeholder={"0"}
                    onChange={(e) => {
                        const newProportion = Number(e.target.value);
                        if (e.target.value !== newProportion.toString()) {
                            // Invalid input !
                            setPackagingMaterial({
                                ...packagingMaterial,
                                proportion: null,
                            });
                            return;
                        }
                        setPackagingMaterial({
                            ...packagingMaterial,
                            proportion: newProportion,
                        });
                    }}
                    unit={"%"}
                    sx={{ minWidth: "150px", minHeight: "40px" }}
                />
            </BodyCell>
        </Fragment>
    );
};

export type MaterialGridProps = {
    productPackaging: ProductFormProductPackaging;
    setProductPackaging: (newPackaging: ProductFormProductPackaging) => void;
};
export const MaterialGrid = (props: MaterialGridProps): JSX.Element => {
    const { t } = useTranslation();
    const { productPackaging, setProductPackaging } = props;

    const { packagingMaterials } = productPackaging;

    if (packagingMaterials.length === 0) {
        return <Typography>{t("Pas d'information")}</Typography>;
    }

    return (
        <Grid
            container
            style={{
                backgroundColor: "#FFFFFF",
                alignItems: "center",
            }}
        >
            <MaterialHeader />
            {packagingMaterials.map((packagingMaterial, idx) => {
                return (
                    <Fragment
                        key={`${productPackaging.packagingFormId}-${idx}-${packagingMaterial.packagingMaterialSetId}`}
                    >
                        <MaterialBody
                            packagingMaterial={packagingMaterial}
                            setPackagingMaterial={(newPackagingMaterial: ProductFormPackagingMaterial) => {
                                const newMatList = packagingMaterials.map((mat) => {
                                    if (mat.packagingMaterialSetId === newPackagingMaterial.packagingMaterialSetId) {
                                        return newPackagingMaterial;
                                    }
                                    return mat;
                                });

                                setProductPackaging({
                                    ...productPackaging,
                                    packagingMaterials: newMatList,
                                });
                            }}
                        />
                    </Fragment>
                );
            })}
        </Grid>
    );
};
