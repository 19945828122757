import { useState } from "react";
import { CircularProgress, useTheme } from "@mui/material";
import { FormPropertyProps } from "../FormProperty";
import { FoodsCheckbox, WhitePopover } from "@foodpilot/foods";
import { DataListOption } from "src/api/sure-api/ssq/useGetOneSsq";
import { FormPropertyHint } from "src/components/FormProperty/FormPropertyHint.tsx";

export const Checkbox = (props: FormPropertyProps) => {
    const theme = useTheme();
    const { property, post, element, canEdit = true, isSubproperty } = props;

    const options =
        property.dataList?.options.sort(
            (optionA: DataListOption, optionB: DataListOption) => optionA.position - optionB.position,
        ) ?? [];

    const defaultValueIds = (property.defaultValue ?? "").split("|");
    const initialValueIds = element && !property.readonly ? element?.value.split("|") : defaultValueIds;
    const initialValue = options.filter((option: DataListOption) => (initialValueIds ?? []).indexOf(option.value) > -1);

    const [value, setValue] = useState<DataListOption[]>(initialValue);

    if (!post) return <CircularProgress />;

    const checkbox = (
        <FoodsCheckbox
            values={options}
            selectedItems={value}
            onChange={(selectedValues: DataListOption[]) => {
                setValue(selectedValues);

                props.onUpdate({
                    postId: post.id,
                    propertyId: Number(property.id),
                    value: selectedValues.map((option: DataListOption) => option.value).join("|"),
                    position: Number(props.position ?? 0),
                });
            }}
            getId={(option: DataListOption) => option.value}
            getName={(option: DataListOption) => option.label}
            FormControlLabelProps={{
                sx: {
                    gap: "3px",
                    ".MuiTypography-root": {
                        ...theme.typography.body,
                    },
                },
            }}
            CheckboxProps={{
                disabled: !canEdit || property.readonly,
            }}
        />
    );

    return property.hint && isSubproperty ?
            <WhitePopover icon={checkbox} body={<FormPropertyHint hint={property.hint} />} size="260px" />
        :   checkbox;
};
