import { ReactNode } from "react";
import { ArrowForward } from "@mui/icons-material";
import { Box, Button, Stack, Typography, CircularProgress, useTheme } from "@mui/material";
import { SectionGrid } from "src/components/SectionGrid/SectionGrid";
import { Post } from "src/api/client-api/post/postSchema";
import { Ssq, Heading } from "src/api/sure-api/ssq/useGetOneSsq";
import { SectionBox } from "@foodpilot/foods";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useQuestionnairesColumns } from "./useQuestionnairesColumns";
import { getVisibleHeadings, getHeadingsCompletions } from "src/utils/ssq";
import { QuestionnairesListHeader } from "./QuestionnairesListHeader";
import { formatDateTime } from "src/utils/formatting.ts";
import { EmptyRowsFallback } from "src/components/Grid/EmptyRowsFallback";

export type HeadingsSectionData = {
    heading: Heading;
    completion?: number;
};

type QuestionnairesListProps = {
    title?: string;
    ssq?: Ssq;
    post?: Post;
    isSsqLoading: boolean;
    isPostLoading: boolean;
    completeQuestionnaireUrl: (heading: Heading) => string;
    scoresSection: ReactNode;
    canEdit?: boolean;
};
export const QuestionnairesList = (props: QuestionnairesListProps) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const questionnairesColumns = useQuestionnairesColumns();

    const {
        title,
        ssq,
        post,
        isSsqLoading,
        isPostLoading,
        completeQuestionnaireUrl,
        scoresSection,
        canEdit = true,
    } = props;

    const headings: Heading[] = ssq ? getVisibleHeadings(ssq) : [];
    const headingsCompletions: number[] = getHeadingsCompletions(headings, post);

    const updatedAtDates = post?.elements;
    let updatedAtDate: string = "-";

    if (updatedAtDates && updatedAtDates.length > 0) {
        const mostRecentDate: Date = new Date(
            Math.max(...updatedAtDates.map((element) => Date.parse(element.updatedAt ?? ""))),
        );

        updatedAtDate = formatDateTime(mostRecentDate, i18n.resolvedLanguage);
    }

    const headingsData: HeadingsSectionData[] = headings.map((heading: Heading, index: number) => {
        return {
            heading: heading,
            completion: !isPostLoading ? headingsCompletions[index] ?? 0 : undefined,
        };
    });

    const questionnaireCompleteText =
        canEdit ? t("questionnaire.complete-questionnaire") : t("questionnaire.consult-questionnaire");

    return (
        <SectionBox
            header={
                <Stack direction="row" alignItems="center" gap="12px">
                    <Stack flex={1}>
                        <QuestionnairesListHeader
                            title={title}
                            isSsqLoading={isSsqLoading}
                            isPostLoading={isPostLoading}
                            headingsCompletions={headingsCompletions}
                        />
                    </Stack>

                    <Stack flex={1}>
                        <Typography variant="h4">{t("Date dernière modification :")}</Typography>
                        <Typography variant="caption">{updatedAtDate}</Typography>
                    </Stack>

                    <Stack direction="row" gap="12px">
                        <Button
                            onClick={() => {
                                if (!headings.length) return;

                                navigate(completeQuestionnaireUrl(headings[0]));
                            }}
                            variant="primary"
                            endIcon={<ArrowForward />}
                            disabled={!headings.length}
                        >
                            {questionnaireCompleteText}
                        </Button>
                    </Stack>
                </Stack>
            }
        >
            <Box display="grid" gridTemplateColumns="3fr minmax(0, 1fr)" gap="32px">
                <Stack alignItems="center">
                    {isSsqLoading ?
                        <CircularProgress />
                    :   <SectionGrid
                            mode="table"
                            columns={questionnairesColumns}
                            data={headingsData}
                            enablePagination={false}
                            renderRowActions={(value) => {
                                const isCompleted = value.row.original.completion >= 100;
                                const goToQuestionnaireText =
                                    !canEdit ? t("questionnaire.consult")
                                    : isCompleted ? t("questionnaire.modify")
                                    : t("questionnaire.complete");

                                return (
                                    <Stack display="inline-flex" direction="row" alignItems="center" gap="12px">
                                        <Button
                                            onClick={() => {
                                                navigate(completeQuestionnaireUrl(value.row.original.heading));
                                            }}
                                            variant="ternary"
                                            endIcon={<ArrowForwardIcon fill={theme.custom.grey[2800]} />}
                                            sx={{ minWidth: "132px" }}
                                        >
                                            {goToQuestionnaireText}
                                        </Button>
                                    </Stack>
                                );
                            }}
                            renderEmptyRowsFallback={() => (
                                <EmptyRowsFallback message={t("Il n'y a aucun thématique à afficher")} />
                            )}
                        />
                    }
                </Stack>

                <Stack
                    sx={{
                        height: "fit-content",
                        position: "sticky",
                        top: "28px",
                    }}
                >
                    {!isSsqLoading && !isPostLoading ? scoresSection : <CircularProgress sx={{ margin: "0 auto" }} />}
                </Stack>
            </Box>
        </SectionBox>
    );
};
