import { Stack, Button, Typography, useTheme } from "@mui/material";
import DoubleCheck from "src/assets/icons/double-check.svg?react";
import { useTranslation } from "react-i18next";
import { AccordionContent, AccordionProduct, DottedBox, FoodsIcon, FormBox } from "@foodpilot/foods";
import { useState, Fragment, useEffect } from "react";
import { MaterialGrid } from "src/pages/Product/ProductForm/Pages/Page3/MaterialGrid";
import { MaterialModal } from "./MaterialModal";
import { PackagingModal } from "./PackagingModal";
import { PackagingReference, usePackagingReference } from "src/api/sure-api/usePackagingReference";
import { t } from "i18next";
import { useMaterialReference } from "src/api/sure-api/usePackagingMaterialSet";
import { ProductFormProductPackaging, ProductFormType } from "../../ProductForm.types";
import { Header } from "./Header";
import { CiteoBlock } from "src/components/Partnership/CiteoBlock";

type Page3Props = {
    product: ProductFormType;
    setProduct: (newProduct: ProductFormType) => void;
};

export const Page3 = (props: Page3Props): JSX.Element => {
    const { product, setProduct } = props;
    const [isPackagingOpen, setIsPackagingOpen] = useState(false);
    const { packagingsReference } = usePackagingReference();

    const { productPackagings } = product;

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
            }}
        >
            {" "}
            <PackagingModal
                isOpen={isPackagingOpen}
                setOpen={setIsPackagingOpen}
                currentProductPackagings={productPackagings}
                allPackagings={packagingsReference}
                onValidate={(newProductPackaging: ProductFormProductPackaging[]) => {
                    setProduct({
                        ...product,
                        productPackagings: newProductPackaging,
                    });
                }}
            />
            <FormBox display="flex" flexDirection="column" gap="32px">
                {productPackagings.length > 0 && (
                    <DisplayPackagings
                        productPackagings={productPackagings}
                        allPackagings={packagingsReference}
                        setProductPackaging={(newPackagings: ProductFormProductPackaging[]) => {
                            setProduct({
                                ...product,
                                productPackagings: newPackagings,
                            });
                        }}
                        openPackageDialog={() => setIsPackagingOpen(true)}
                    />
                )}
                {productPackagings.length === 0 && <NoPackaging onAdd={() => setIsPackagingOpen(true)} />}
            </FormBox>
            <CiteoBlock />
        </div>
    );
};

const usePackagingToAccordion = (
    productPackagings: ProductFormProductPackaging[],
    packagingsReference: PackagingReference[],
    onChange: (newPackagings: ProductFormProductPackaging[]) => void,
    editMaterial: (packagingId: number) => void,
    setPackagingDrawer: (packaging: ProductFormProductPackaging | null) => void,
    selectedPackagingFormId: number | null,
): AccordionContent[] => {
    const theme = useTheme();

    const content = productPackagings.map((productPackaging) => {
        const foundForm = packagingsReference.find((form) => form.id === productPackaging.packagingFormId);

        const isSelected = selectedPackagingFormId === productPackaging.packagingFormId;
        const nameFound = foundForm?.name ?? t("Non trouvé");
        const page: AccordionContent = {
            title: (
                <Fragment>
                    <Typography variant={isSelected ? "h4" : "body-medium"}>{nameFound}</Typography>
                </Fragment>
            ),
            content: (
                <Fragment>
                    <MaterialGrid
                        productPackaging={productPackaging}
                        setProductPackaging={(newProductPackaging: ProductFormProductPackaging) => {
                            const newPackList = productPackagings.map((pack) => {
                                if (pack.packagingFormId === newProductPackaging.packagingFormId) {
                                    return newProductPackaging;
                                }
                                return pack;
                            });
                            onChange(newPackList);
                        }}
                    />
                    <Button
                        variant="text"
                        startIcon={<DoubleCheck />}
                        sx={{ marginTop: "2rem", color: theme.palette.primary[700] }}
                        onClick={() => editMaterial(productPackaging.packagingFormId)}
                    >
                        {t("Gérer mes matériaux")}
                    </Button>
                </Fragment>
            ),
            onOpenAction: () => {
                if (isSelected) {
                    return setPackagingDrawer(null);
                }
                setPackagingDrawer(productPackaging);
            },
        };
        return page;
    });
    return content;
};

type DisplayPackagingsProps = {
    productPackagings: ProductFormProductPackaging[];
    allPackagings: PackagingReference[];
    setProductPackaging: (newPackagings: ProductFormProductPackaging[]) => void;
    openPackageDialog: () => void;
};
const DisplayPackagings = (props: DisplayPackagingsProps) => {
    const { productPackagings, setProductPackaging, allPackagings } = props;
    const [isMaterialOpen, setIsMaterialOpen] = useState(false);
    const { data: materialSets } = useMaterialReference();

    const [packagingDrawer, setPackagingDrawer] = useState<ProductFormProductPackaging | null>(null);

    console.log("PACKAGING : ", packagingDrawer);
    useEffect(() => {
        // Update the Packaging Drawer if a value changes;
        if (packagingDrawer === null) return;

        const selectedPackagingsFromDrawer = productPackagings.find(
            (productPack) => productPack.packagingFormId === packagingDrawer.packagingFormId,
        );
        setPackagingDrawer(selectedPackagingsFromDrawer ?? null);
    }, [productPackagings]);

    // Kilian : This will need to be reworked.
    // Maybe an AccordionGroup, that would have some Accordion inside within a lop.
    const accordionPages = usePackagingToAccordion(
        productPackagings,
        allPackagings,
        setProductPackaging,
        () => setIsMaterialOpen(true),
        setPackagingDrawer,
        packagingDrawer?.packagingFormId ?? null,
    );

    useEffect(() => {
        // see AccordionProduct onOpenAction property
        // first productPackaging because defaultIndex is 0 see below.
        setPackagingDrawer(productPackagings[0]);
    }, []);

    return (
        <div>
            {packagingDrawer !== null && materialSets !== undefined && (
                <MaterialModal
                    isOpen={isMaterialOpen}
                    setOpen={setIsMaterialOpen}
                    productPackaging={packagingDrawer}
                    allMaterialSets={materialSets}
                    onValidate={(value) => {
                        const newPackList = productPackagings.map((pack) => {
                            if (pack.packagingFormId === value.packagingFormId) {
                                return value;
                            }
                            return pack;
                        });
                        setProductPackaging(newPackList);
                    }}
                />
            )}
            <Header openModal={props.openPackageDialog} />
            <AccordionProduct pages={accordionPages} defaultIndex={0} />
        </div>
    );
};

type NoPackagingProps = {
    onAdd: () => void;
};
const NoPackaging = (props: NoPackagingProps) => {
    const { t } = useTranslation();
    return (
        <DottedBox>
            <Stack alignItems="center" padding="47px" gap="16px">
                <Typography variant="big-medium">{"Aucun emballage ajouté"}</Typography>
                <Button
                    variant="text"
                    startIcon={<FoodsIcon icon="plus" size={2} />}
                    onClick={props.onAdd}
                    sx={{ padding: 0 }}
                >
                    {t("Ajouter ou retirer des emballages")}
                </Button>
            </Stack>
        </DottedBox>
    );
};
