import z from "zod";
import { useQuery, useMutation } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";

type Options = {
    orderByDate?: boolean;
};

export const useGetAllCampaigns = (options?: Options) => {
    let url = "/api/campaigns";
    if (options?.orderByDate) {
        url = url.concat("?order[startDate]=asc");
    }
    const query = useQuery({
        queryKey: "getAllCampaigns",
        cacheTime: 0,
        staleTime: 0,
        queryFn: async () => {
            return await loggedInApiCall.get(url);
        },
        select: (response) => {
            const campaigns = response.data["hydra:member"];
            const data = zodParseWithLog(campaigns, CampaignSchema.array(), "getAllCampaigns");
            return data;
        },
    });

    return {
        campaigns: query.data ?? [],
        ...query,
    };
};

export const useCreateCampaign = () => {
    const query = useMutation({
        mutationFn: async (campaign: CampaignWrite) => {
            const response =
                campaign.id === undefined ?
                    await loggedInApiCall.post("/api/campaigns", campaign)
                :   await loggedInApiCall.put("/api/campaigns/" + campaign.id, campaign);

            return response;
        },
    });

    return query;
};

export const useDeleteCampaign = () => {
    const query = useMutation({
        mutationFn: async (id: number) => {
            const response = await loggedInApiCall.delete("/api/campaigns/" + id);

            return response;
        },
    });

    return query;
};

export type Campaign = {
    "@id": string;
    id: number;
    label: string;
    startDate: string;
    endDate: string;
    active: boolean;
};

export const CampaignSchema: z.ZodSchema<Campaign> = z.object({
    "@id": z.string(),
    id: z.number(),
    label: z.string(),
    startDate: z.string(),
    endDate: z.string(),
    active: z.boolean(),
});

export type CampaignWrite = {
    id?: number;
    label: string;
    startDate: string;
    endDate: string;
    active: boolean;
};

export const CampaignWriteSchema: z.ZodSchema<CampaignWrite> = z.object({
    id: z.number().optional(),
    label: z.string(),
    startDate: z.string(),
    endDate: z.string(),
    active: z.boolean(),
});
