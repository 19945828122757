import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Typography, useTheme, CircularProgress, Box } from "@mui/material";
import { FormPropertyProps } from "../FormProperty";
import CalendarIcon from "src/assets/icons/calendar.svg?react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { formatDateTime } from "src/utils/formatting";
import locale from "dayjs/locale/*";
import { formatUnitAbbreviation } from "src/utils/formatting";
import { WhitePopover, FormUnit } from "@foodpilot/foods";
import { FormPropertyHint } from "src/components/FormProperty/FormPropertyHint.tsx";
import { useScoreContext } from "src/context/ScoreContext";
import { getFormattedScoreByScoreId } from "src/utils/ssq";

export const Date = (props: FormPropertyProps) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { displayedScoreId, displayedVariationId, formattedScores } = useScoreContext();
    const { property, post, element, canEdit = true, isSubproperty, isMultivalued } = props;

    const defaultValue = property.defaultValue ?? "";
    const initialValue = element && !property.readonly ? element.value ?? "" : defaultValue;

    const formattedScore = getFormattedScoreByScoreId(formattedScores, displayedScoreId);
    const propertyScore = formattedScore?.properties?.[property.id];

    const scoreVariations = formattedScore?.scoreVariations ?? {};
    const scoreVariation = scoreVariations?.[displayedVariationId ?? 0];

    const propertyVariations = propertyScore?.variations ?? {};
    const propertyVariation = propertyVariations?.[displayedVariationId ?? 0];

    const [value, setValue] = useState(initialValue);
    const [locale, setLocale] = useState<locale.Locale | null>(null);

    if (!post) return <CircularProgress />;

    const unit = formatUnitAbbreviation(property.unit);

    // [ds] to localize for other langs, doing dynamic import based on i18n.resolvedLanguage can be done.
    // For now dynamic imports break Vite which has to be properly configured. This may do the trick:
    // https://github.com/vite-plugin/vite-plugin-dynamic-import#readme
    import("dayjs/locale/fr").then((locale: locale.Locale) => {
        setLocale(locale);
    });

    const datePicker = (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.resolvedLanguage}>
            <DatePicker
                disabled={canEdit === false || property.readonly}
                defaultValue={dayjs(value, locale?.formats.L)}
                onChange={(date: Dayjs | null) => {
                    if (!date) {
                        return;
                    }

                    const value = date.format(locale?.formats.L);

                    setValue(value);

                    props.onUpdate({
                        postId: post.id,
                        propertyId: Number(property.id),
                        value: dayjs(date).format("YYYY-MM-DD"),
                        position: Number(props.position ?? 0),
                    });
                }}
                format={locale?.formats.L}
                dayOfWeekFormatter={(date: Dayjs) => {
                    const weekday = formatDateTime(date.valueOf(), i18n.resolvedLanguage, { weekday: "short" });

                    return weekday.charAt(0).toUpperCase() + weekday.substring(1, 3);
                }}
                slots={{
                    openPickerIcon: CalendarIcon,
                }}
                slotProps={{
                    popper: {
                        sx: {
                            ".MuiPaper-root": {
                                boxShadow: "0 4px 20px 0 rgba(81, 78, 78, 0.12)",
                                border: `1px solid ${theme.custom.grey[500]}`,
                                borderRadius: "12px",
                            },
                        },
                    },
                    layout: {
                        sx: {
                            ".MuiPickersCalendarHeader-root": {
                                borderBottom: `1px solid ${theme.custom.grey[500]}`,
                                display: "flex",
                                gap: "12px",
                                padding: "12px",
                                margin: 0,
                                ".MuiPickersCalendarHeader-labelContainer": {
                                    ...theme.typography.body,
                                },
                            },
                            ".MuiPickersYear-yearButton": {
                                ...theme.typography.body,
                                "&.Mui-selected": {
                                    background: `${theme.palette.primary[600]} !important`,
                                    color: "#fff",
                                },
                            },
                            ".MuiDayCalendar-weekDayLabel": {
                                color: theme.custom.grey[1400],
                            },
                            ".MuiPickersDay-today": {
                                background: "#fff",
                                borderColor: `${theme.palette.primary[600]} !important`,
                            },
                            ".MuiButtonBase-root": {
                                ":hover": {
                                    background: theme.custom.grey[400],
                                },
                                "&.Mui-selected": {
                                    background: `${theme.palette.primary[600]} !important`,
                                    color: "#fff",
                                },
                            },
                        },
                    },
                    textField: {
                        sx: {
                            ".MuiInputBase-root": {
                                minWidth: "220px",
                                height: "40px",
                                borderRadius: "40px",
                                border: `1px solid ${theme.custom.grey[500]}`,
                                ":hover": {
                                    backgroundColor: theme.custom.grey[300],
                                },
                                "&.Mui-disabled": {
                                    background: theme.custom.grey[500],
                                },
                                "&.Mui-focused": {
                                    outline: `2px solid ${theme.custom.grey[2800]}`,
                                },
                                ".MuiInputBase-input": {
                                    ...theme.typography.body,
                                    paddingInline: "20px",
                                },
                                ".MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                },
                            },
                            ".MuiButtonBase-root": {
                                background: "transparent",
                                color: theme.custom.grey[2800],
                                padding: "12px",
                                ":hover": {
                                    background: "transparent",
                                },
                            },
                        },
                    },
                }}
            />
        </LocalizationProvider>
    );

    return (
        <Stack direction="column" gap="8px">
            <Stack direction="row" alignItems="center" gap="8px">
                {property.hint && isSubproperty ?
                    <WhitePopover icon={datePicker} body={<FormPropertyHint hint={property.hint} />} size="260px" />
                :   datePicker}
                {unit && <Typography variant="body">{unit}</Typography>}
                {!!propertyScore?.score && isSubproperty && !isMultivalued && (
                    <FormUnit
                        value={Number((propertyVariation?.score ?? propertyScore?.score).toFixed(3))}
                        unit={scoreVariation?.unit_abbreviation ?? scoreVariation?.unit}
                    />
                )}
            </Stack>
            {defaultValue && (
                <Typography variant="caption" color={theme.custom.grey[1500]}>
                    {!property.readonly ?
                        t("Valeur par défaut :") + " " + defaultValue
                    :   t("Cette valeur est en lecture seule")}
                </Typography>
            )}
        </Stack>
    );
};
