import z from "zod";
import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { IngredientService } from "src/pages/Ingredient/IngredientService";
import { IRI } from "src/types";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { Scores, ScoresSchema } from "../post/scoreSchema";
import { getDimensionsQueryParam, ScoreEntityEndpointOptions } from "src/api/apiHelpers";

export const useGetOneIngredient = (ingredientId: string, options?: ScoreEntityEndpointOptions) => {
    const { campaign, dimensions = [["main"]], variations } = options || {};

    const query = useQuery({
        queryKey: ["getOneIngredient", ingredientId, campaign, variations],
        cacheTime: 0,
        enabled: true,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const url = `/api/ingredients/${ingredientId}`;

            const response = await loggedInApiCall.get<Ingredient>(url, {
                params: {
                    ...options,
                    dimensions: getDimensionsQueryParam(dimensions),
                },
            });
            return response;
        },
        select: (request) => {
            const ingredient = IngredientService.makeSectorsFromIngredient(request.data);
            const data = zodParseWithLog(ingredient, IngredientZodSchema, "oneIngredient");
            return data;
        },
    });

    return {
        ingredient: query.data,
        ...query,
    };
};

type Domain = {
    "@id": IRI;
    name: string;
};
const DomainSchema: z.ZodSchema<Domain> = z.object({
    "@id": z.string(),
    name: z.string(),
});

export type IngredientLine = {
    "@id": IRI;
    id: number;
    name: string;
};
const IngredientLineZodSchema: z.ZodSchema<IngredientLine> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
});

type IngredientFromIngredientSupplierNested = {
    ingredient: {
        ingredientLine?: IngredientLine;
    };
};

type IngredientSupplierNested = IRI | IngredientFromIngredientSupplierNested;

const IngredientSupplierNestedSchema: z.ZodSchema<IngredientSupplierNested> = z.union([
    z.string(),
    z.object({
        ingredient: z.object({
            ingredientLine: IngredientLineZodSchema.optional(),
        }),
    }),
]);

type SupplierFromIngSupplier = {
    "@id": IRI;
    id: number;
    name: string;
    clientId?: string;
    ingredientSuppliers: IngredientSupplierNested[];

    // Maybe it's better if this key is made in the backend...
    sectors?: string[];
};
const SupplierFromIngSupplierSchema: z.ZodSchema<SupplierFromIngSupplier> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
    clientId: z.string().optional(),
    ingredientSuppliers: IngredientSupplierNestedSchema.array(),

    sectors: z.string().array().optional(),
});

type IngredientFromIngSupplier =
    | IRI
    | {
          id: number;
          ingredientLine?: IngredientLine;
      };

const IngredientFromIngSupplierSchema: z.ZodSchema<IngredientFromIngSupplier> = z.union([
    z.string(),
    z.object({
        id: z.number(),
        ingredientLine: IngredientLineZodSchema.optional(),
    }),
]);

export type IngredientSupplier = {
    "@id": IRI;
    id: number;
    ingredient: IngredientFromIngSupplier;
    supplier: SupplierFromIngSupplier;
    grossSales?: number;
    quantity?: number;
    unit?: string;
};
const IngredientSupplierSchema: z.ZodSchema<IngredientSupplier> = z.object({
    "@id": z.string(),
    id: z.number(),
    ingredient: IngredientFromIngSupplierSchema,
    supplier: SupplierFromIngSupplierSchema,
    grossSales: z.number().optional(),
    quantity: z.number().optional(),
    unit: z.string().optional(),
});

export type Ingredient = {
    "@id": IRI;
    id: number;
    name: string;
    clientId?: string;
    domains?: Domain[];
    externalId?: number;
    ingredientSuppliers?: IngredientSupplier[];
    ingredientReferenceId?: number;
    ingredientLine?: IngredientLine;
    organic?: boolean;
    canModify: boolean;
    canDelete: boolean;
    scores: Scores;
    prevScores?: Scores;
    prevCampaignName?: string;
};

export const IngredientZodSchema: z.ZodSchema<Ingredient> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
    clientId: z.string().optional(),
    domains: DomainSchema.array().optional(),
    externalId: z.number().optional(),
    ingredientSuppliers: IngredientSupplierSchema.array().optional(),
    ingredientLine: IngredientLineZodSchema.optional(),
    ingredientReferenceId: z.number().optional(),
    organic: z.boolean().optional(),
    canModify: z.boolean(),
    canDelete: z.boolean(),
    scores: ScoresSchema,
    prevScores: ScoresSchema.optional(),
    prevCampaignName: z.string().optional(),
});
