import { WhitePopover, FoodsSelect } from "@foodpilot/foods";
import { Stack, Typography } from "@mui/material";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { DataListOption } from "src/api/sure-api/ssq/useGetOneSsq";
import { formatUnitAbbreviation } from "src/utils/formatting";
import { FormPropertyProps } from "../../FormProperty";
import { FormPropertyHint } from "../../FormPropertyHint";

type SsqSelectProps = FormPropertyProps & {
    options: DataListOption[];
};

export const SsqSelect = (props: SsqSelectProps) => {
    const { t } = useTranslation();
    const { property, post, isSubproperty } = props;

    if (post === undefined) return;

    return (
        <Stack direction="column" gap="8px">
            {property.hint && isSubproperty ?
                <WhitePopover
                    icon={<_SsqSelect {...props} />}
                    body={<FormPropertyHint hint={property.hint} />}
                    size="260px"
                />
            :   <_SsqSelect {...props} />}

            {property.readonly && (
                <Typography variant="small-medium">{t("Cette valeur est en lecture seule")}</Typography>
            )}
        </Stack>
    );
};

const _SsqSelect = (props: SsqSelectProps) => {
    const { property, element, post, canEdit, options } = props;
    const { t } = useTranslation();

    const defaultValue = property.defaultValue ?? "";
    const initialValue = element && !property.readonly ? element.value ?? "" : defaultValue;
    const _selectedOption = options.find((option) => option.value === initialValue) ?? null;
    const selectedOption = useRef(_selectedOption);

    const unit = formatUnitAbbreviation(property.unit);

    if (post === undefined) return;

    return (
        <FoodsSelect
            selectedOption={selectedOption.current}
            // originalValue={defaultValue}
            options={options}
            onChange={(selectedValue) => {
                props.onUpdate({
                    postId: post.id,
                    propertyId: property.id,
                    value: selectedValue?.value ?? "",
                    position: Number(props.position ?? 0),
                });
                // Tomorrow, should act on the `mutate.onSuccess`
                selectedOption.current = selectedValue;
            }}
            getId={(item) => item.position}
            getName={(item) => item.label}
            disabled={canEdit === false || property.readonly}
            textOptions={{
                placeholder: t("component.select.placeholder"),
                searchPlaceholder: t("component.select.searchPlaceholder"),
                emptyOptionsText: t("component.select.noOptions"),
                defaultValueText: t("component.select.defaultValue"),
                noResult: t("component.select.noResultFound"),
            }}
            noValue={t("component.select.unselectValue")}
            unit={unit}
        />
    );
};
