import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ComparisonIndicator } from "@foodpilot/foods";
import { formatNumber } from "src/utils/formatting.ts";

export type ScoreComparisonProps = {
    referenceScoreValue: number;
    scoreValueToCompare: number;
    unit?: string;
    precision?: number;
    reportName: string;
};

export const ScoreComparison = (props: ScoreComparisonProps): JSX.Element => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const theme = useTheme();

    const { referenceScoreValue, scoreValueToCompare, unit, precision, reportName } = props;

    const referenceScoreValueFormatted = formatNumber(referenceScoreValue, i18n.resolvedLanguage, {
        maximumFractionDigits: precision,
    });

    const difference = referenceScoreValue - scoreValueToCompare;
    const percentage = (difference / referenceScoreValue) * 100;
    const isWorse = difference < 0;

    return (
        <Box
            sx={{
                display: "flex",
                gap: "0.6rem",
                alignItems: "center",
            }}
        >
            <ComparisonIndicator
                value={Math.abs(Number(percentage.toFixed(2)))}
                direction={isWorse ? "up" : "down"}
                color={isWorse ? "red" : "green"}
            />

            <Typography variant="body2">
                {t("score_reference.in_relation")} <strong>{reportName}</strong> (
                <strong>{referenceScoreValueFormatted}</strong>
                <span style={{ color: theme.custom.grey[1400] }}> {unit} </span>)
            </Typography>
        </Box>
    );
};
