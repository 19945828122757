import axios, { CreateAxiosDefaults } from "axios";
import { getSureApiUrl } from "./utils";
import { getInexwebInfo } from "src/utils/token";
import { refreshTokenAxiosHandler } from "./asyncUtils";

const adminConfig: CreateAxiosDefaults = {
    baseURL: getSureApiUrl(),
    headers: {
        common: {
            "Content-Type": "application/ld+json",
            Accept: "application/ld+json",
        },
    },
};

export const sureApiCall = axios.create({ ...adminConfig });

sureApiCall.interceptors.response.use(
    (axiosResponse) => {
        return axiosResponse;
    },
    async (error) => {
        await refreshTokenAxiosHandler(error);

        // Here, we want to send back error to tanstack if unable to do anything with it.
        return Promise.reject(error);
    },
);

const inexwebConfig: CreateAxiosDefaults = {
    baseURL: getSureApiUrl(),
    headers: {
        common: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    },
};
export const inexwebApiCall = axios.create({ ...inexwebConfig });

inexwebApiCall.interceptors.request.use(
    // onFullfill
    (config) => {
        const token = getInexwebInfo();
        config.headers["X-Token"] = token.token;
        config.headers["X-Company"] = token.company;

        return config;
    },
    // onError
    async (error) => {
        await refreshTokenAxiosHandler(error);

        // The Error behavior is not very clear, so we do nothing.
        console.error("Error inside Axios Client: ", error);
    },
);
