import { ReactNode } from "react";
import { Stack } from "@mui/material";
import { Heading } from "src/api/sure-api/ssq/useGetOneSsq";
import { ScoresSection } from "src/components/QuestionnairesList/Scores/ScoresSection";
import { QuestionnairesList } from "src/components/QuestionnairesList/QuestionnairesList";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { getScoreIdsByType } from "src/utils/scores";
import { ScoreProvider } from "src/context/ScoreContext";
import { URLHandler } from "src/services/URLHandler";
import { useGetProductPost } from "src/api/client-api/post/useGetProductPost";
import { useProductContext } from "../ProductContext";
import { Loading } from "src/components/utils/Loading";

export const Questionnaire = (): ReactNode => {
    const { product, ssq, isSsqLoading } = useProductContext();
    const { scoreTypes, selectedCompany, currentCampaign } = useFoodpilotContext();

    const companyId = selectedCompany ? selectedCompany.id : null;
    const { post, isLoading: isPostLoading } = useGetProductPost(product?.id, companyId, currentCampaign?.id);

    const productsScoresIds = getScoreIdsByType("product", scoreTypes);

    if (!product) {
        return <Loading />;
    }
    if (selectedCompany === null) return;

    return (
        <Stack gap="60px">
            <Stack gap="28px">
                <QuestionnairesList
                    ssq={ssq}
                    post={post}
                    isSsqLoading={isSsqLoading}
                    isPostLoading={isPostLoading}
                    completeQuestionnaireUrl={(heading: Heading) => {
                        const url = URLHandler.product.ssqHeading(selectedCompany.id, product.id, heading.id);
                        return url;
                    }}
                    scoresSection={
                        <ScoreProvider type="product" formattedScores={post?.formattedScores}>
                            <ScoresSection post={post} headings={ssq?.headings} scoresIds={productsScoresIds} />
                        </ScoreProvider>
                    }
                />
            </Stack>
        </Stack>
    );
};
