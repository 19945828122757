import { createContext, ReactNode, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import {
    ProductReferenceScores,
    useGetProductReferenceScores,
} from "src/api/client-api/productReferenceScores/useGetProductReferenceScores";
import { Product, useGetOneProduct } from "src/api/client-api/products/useGetOneProduct";
import { Ssq, useGetOneSsq } from "src/api/sure-api/ssq/useGetOneSsq";
import { useFoodpilotContext } from "src/context/FoodpilotContext";

type ProductContextInitialValues = {
    product: Product | undefined;
    productReferenceScores: ProductReferenceScores;
    variations: Record<number, number>;
    setVariations: (variations: Record<number, number>) => void;
    isLoading: boolean;
    isRefetching: boolean;

    ssq: Ssq | undefined;
    isSsqLoading: boolean;
};

const ProductContext = createContext<ProductContextInitialValues | null>(null);

type ProductContextProviderProps = { children: ReactNode };
export const ProductContextProvider = (props: ProductContextProviderProps) => {
    const { children } = props;
    const { productId } = useParams();

    const { currentCampaign } = useFoodpilotContext();
    const [variations, setVariations] = useState<Record<number, number>>({});
    const { product, isLoading, isRefetching } = useGetOneProduct(productId ?? "A completely bad ID", {
        campaign: currentCampaign?.id,
        variations: variations,
    });
    const { productReferenceScores = [] } = useGetProductReferenceScores(productId ?? "0");

    const ssqId = product?.ssqId;
    const { ssq, isLoading: isSsqLoading } = useGetOneSsq(ssqId ?? 0);

    const items: ProductContextInitialValues = {
        product,
        productReferenceScores,
        variations,
        setVariations,
        isLoading,
        isRefetching,
        ssq,
        isSsqLoading,
    };

    return <ProductContext.Provider value={items}>{children}</ProductContext.Provider>;
};

export const useProductContext = () => {
    const context = useContext(ProductContext);

    if (context) {
        return context;
    }

    throw new Error(`useProductContext must be used within an ProductContextProvider`);
};
