import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ActivitiesIcon from "src/assets/icons/activities.svg?react";
import InformationIcon from "src/assets/icons/information.svg?react";
import QuestionnaireIcon from "src/assets/icons/questionnaire.svg?react";
import ScoresIcon from "src/assets/icons/scores.svg?react";
import { SectionTab, SectionTabs } from "@foodpilot/foods";
import { IngredientScores } from "./IngredientScores";
import { IngredientActivitiesData } from "./IngredientActivitiesData";
import { useIngredientContext } from "./ExistingIngredientContext";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { Questionnaire } from "./Questionnaire/Questionnaire";
import { Informations } from "./Informations";
import { CampaignSelector } from "src/components/Selector";
import { URLHandler } from "src/services/URLHandler";
import { useGetIngredientReferenceScores } from "src/api/client-api/ingredientReferenceScores/useGetIngredientReferenceScores";

type SectionKeys = "scores" | "questionnaire" | "activities" | "informations";
type Sections = {
    [section in SectionKeys]?: SectionTab;
};

type IngredientDetailsProps = {
    section: SectionKeys;
};
export const IngredientDetails = (props: IngredientDetailsProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const { section } = props;
    const { selectedCompany, isUserOfTypeSupplier } = useFoodpilotContext();
    const { ingredient } = useIngredientContext();

    const { ingredientReferenceScores = [] } = useGetIngredientReferenceScores(ingredient?.id);

    if (ingredient === undefined || selectedCompany === null) {
        return;
    }

    const isSupplier = isUserOfTypeSupplier();

    const sections: Sections = {};

    sections["scores"] = {
        name: t("Scores & indicateurs clés"),
        icon: <ScoresIcon width="22px" />,
        component: <IngredientScores ingredient={ingredient} ingredientReferenceScores={ingredientReferenceScores} />,
        onClick: () => {
            const url = URLHandler.ingredient.scores(selectedCompany.id, ingredient.id);
            navigate(url);
        },
    };

    sections["questionnaire"] = {
        name: t("Questionnaires"),
        icon: <QuestionnaireIcon width="16px" />,
        component: <Questionnaire />,
        onClick: () => {
            const url = URLHandler.ingredient.ssq(selectedCompany.id, ingredient.id);
            navigate(url);
        },
    };

    if (isSupplier === false) {
        sections["activities"] = {
            name: t("Données d’activité"),
            icon: <ActivitiesIcon width="20px" />,
            component: <IngredientActivitiesData ingredient={ingredient} />,
            onClick: () => {
                const url = URLHandler.ingredient.activity(selectedCompany.id, ingredient.id);
                navigate(url);
            },
        };
    }

    sections["informations"] = {
        name: t("Informations"),
        icon: <InformationIcon width="16px" />,
        component: <Informations ingredient={ingredient} isSupplier={isSupplier} />,
        onClick: () => {
            const url = URLHandler.ingredient.information(selectedCompany.id, ingredient.id);
            navigate(url);
        },
    };

    return (
        <Box component="div" sx={{ marginLeft: "auto", marginRight: "auto" }}>
            <Box
                sx={{
                    width: "auto",
                    background: "#fff",
                    padding: "28px 52px",
                    paddingBottom: 0,
                    position: "relative",
                    borderBottom: `1px solid ${theme.custom.grey[500]}`,
                }}
            >
                <Stack direction="row" alignItems="center" gap="16px">
                    <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                        <ArrowBackIcon
                            style={{
                                cursor: "pointer",
                                margin: "8px",
                            }}
                            onClick={() => navigate(URLHandler.ingredient.list(selectedCompany.id))}
                        />
                        <Typography variant="h1">{ingredient.name}</Typography>
                    </Box>
                    <CampaignSelector />
                </Stack>

                <Divider sx={{ marginTop: "28px" }} />
                <SectionTabs
                    tabs={Object.values(sections)}
                    value={Object.keys(sections).indexOf(section)}
                    renderComponents={false}
                    sx={{
                        position: "relative",
                        bottom: "-1px",
                    }}
                />
            </Box>

            <Stack gap="28px" sx={{ padding: "40px 52px" }}>
                {sections[section]?.component}
            </Stack>
        </Box>
    );
};
