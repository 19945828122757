import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { SearchBox } from "@foodpilot/foods";

type GridPageHeaderProps = {
    mainBoardName: string;
    listItemName: string;
    title: string;
    filter?: string;
    setFilter?: (newFilter: string) => void;
};
export const GridPageHeader = (props: GridPageHeaderProps) => {
    const { t } = useTranslation();
    const { mainBoardName, listItemName, filter = null, setFilter, title } = props;

    return (
        <Stack direction="row" useFlexGap alignItems="center" justifyContent="space-between">
            <Stack direction="row" useFlexGap alignItems="center">
                <Typography variant="h1">{title}</Typography>
                <Button
                    disabled
                    variant="white-filled"
                    sx={{
                        marginLeft: "12px",
                        height: "40px",
                        paddingLeft: "12px",
                        paddingRight: "16px",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "20px",
                        }}
                    >
                        {mainBoardName}
                    </Typography>
                </Button>
                <Button
                    disabled
                    variant="black-filled"
                    sx={{
                        marginLeft: "10px",
                        height: "40px",
                        paddingLeft: "12px",
                        paddingRight: "16px",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "20px",
                            color: "white",
                        }}
                    >
                        {listItemName}
                    </Typography>
                </Button>
            </Stack>
            {setFilter && (
                <SearchBox
                    variant="standalone"
                    value={filter}
                    placeholder={t("Rechercher un élément")}
                    onChange={setFilter}
                />
            )}
        </Stack>
    );
};
