import { useQuery } from "react-query";
import { sureApiCall } from "src/api/axiosClient/sureApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { z } from "zod";

export const useGetAllSsq = () => {
    const query = useQuery({
        queryKey: ["getAllSsq"],
        cacheTime: 0,
        queryFn: async () => {
            return await sureApiCall.get(`/api/ssqs?itemsPerPage=200`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
        },
        select: (response) => {
            if (!response) {
                return;
            }

            const ssqs = response.data;
            const data = zodParseWithLog(ssqs, SsqListSchema, "getAllSsq");
            return data;
        },
    });

    return {
        ssqs: query.data,
        ...query,
    };
};

export const SsqListSchema = z
    .object({
        id: z.number(),
        title: z.string(),
    })
    .array();
