import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { Box, Button, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { FoodsIllustrations, WhiteBox } from "@foodpilot/foods";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { useGetOneSsq } from "src/api/sure-api/ssq/useGetOneSsq";
import { getHeadingsCompletions, getVisibleHeadings } from "src/utils/ssq";
import { useGetCompanyPost } from "src/api/client-api/post/useGetCompanyPost";
import { CompanyScores } from "src/pages/Company/Scores/CompanyScores";
import dayjs from "dayjs";
import { Post } from "src/api/client-api/post/postSchema";
import { Campaign } from "src/api/client-api/useCampaign";
import { useState } from "react";
import { getSureApiUrl } from "src/api/axiosClient/utils";
import { WrapIf } from "src/components/WrapIf";
import { URLHandler } from "src/services/URLHandler";

const useTotalCompletion = (post: Post): number | undefined => {
    const { selectedCompany } = useFoodpilotContext();
    const ssqId = selectedCompany?.companySector?.ssqId;
    const { ssq } = useGetOneSsq(ssqId ?? 0);
    const headings = ssq ? getVisibleHeadings(ssq) : [];
    const headingsCompletions: number[] = getHeadingsCompletions(headings, post);
    const completions = headingsCompletions.reduce((a: number, b: number) => a + b, 0);
    const total = headingsCompletions.length ? Math.round(completions / headingsCompletions.length) : 0;

    return total;
};

export const Synthesis = () => {
    const {
        currentCampaign,
        selectedCompany,
        selectedCompanyVariations,
        setSelectedCompanyVariations,
        areCompaniesRefetching,
        campaigns,
    } = useFoodpilotContext();

    const previousCampaign = [...campaigns]
        .reverse()
        .find((c) => dayjs(c.startDate).isBefore(dayjs(currentCampaign?.startDate)));

    const { post } = useGetCompanyPost(selectedCompany?.id, currentCampaign?.id);

    const { post: previousPost } = useGetCompanyPost(selectedCompany?.id, previousCampaign?.id);
    const previousTotal = useTotalCompletion(previousPost as Post);
    const total = useTotalCompletion(post as Post);

    const ssqId = selectedCompany?.companySector?.ssqId;

    const { ssq } = useGetOneSsq(ssqId ?? 0);

    const headings = ssq ? getVisibleHeadings(ssq) : [];

    const navigate = useNavigate();

    const handleClick = () => {
        if (headings.length === 0) return;
        if (selectedCompany === null) return;

        const url = URLHandler.company.ssqHeading(selectedCompany.id, headings[0].id);
        navigate(url);
    };
    const downloadDisabled = total === 0;

    if (!selectedCompany) return <Typography variant="big-bold">{t("error.wrongCompany")}</Typography>;

    return (
        <Stack gap={2} maxWidth={"1280px"}>
            <Box alignSelf={"end"} marginTop={"-4.2rem"} marginBottom={1}>
                <WrapIf
                    condition={downloadDisabled}
                    wrapper={(children) => (
                        <Tooltip title={t("bred.questionnaire_complete_needed")}>{children}</Tooltip>
                    )}
                >
                    <Box>
                        <Button
                            variant="primary"
                            LinkComponent={downloadDisabled ? "button" : "a"}
                            disabled={downloadDisabled}
                            href={`${getSureApiUrl()}/api/bred/feed-report-new`}
                        >
                            <FileDownloadOutlinedIcon />
                            {t("bred.download_report")}
                        </Button>
                    </Box>
                </WrapIf>
            </Box>
            {total === 0 ?
                <WhiteBox sx={{ p: 3 }}>
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        divider={<Divider orientation="vertical" flexItem />}
                        gap={4}
                    >
                        <Stack flex={3} direction={"row"} gap={2} justifySelf={"start"}>
                            <FoodsIllustrations illustration="questionnaire" />
                            <Stack justifyContent={"center"} gap={1}>
                                <Typography variant="body-bold">
                                    {t("bred.synthesis.info.complete_questionnaire", {
                                        year: currentCampaign?.label,
                                    })}
                                </Typography>
                                <Typography variant="body">
                                    {t("bred.synthesis.info.questionnaire_completed")}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack justifyContent={"center"}>
                            <Button variant="primary" onClick={handleClick} disabled={headings.length === 0}>
                                {t("questionnaire.complete-questionnaire")}
                            </Button>
                        </Stack>
                    </Stack>
                </WhiteBox>
            :   previousCampaign &&
                previousTotal === 0 && (
                    <DataForPreviousPeriod previousCampaign={previousCampaign}></DataForPreviousPeriod>
                )
            }
            <CompanyScores
                company={selectedCompany}
                variations={selectedCompanyVariations}
                setVariations={setSelectedCompanyVariations}
                isRefetching={areCompaniesRefetching}
            />
        </Stack>
    );
};

type DataForPreviousPeriodProps = {
    previousCampaign: Campaign;
};
const DataForPreviousPeriod = (props: DataForPreviousPeriodProps) => {
    const { previousCampaign } = props;
    const { setCurrentCampaign } = useFoodpilotContext();
    const [hidden, hide] = useState(false);
    const handleNavigatePreviousCampaign = () => {
        setCurrentCampaign(previousCampaign);
    };
    const handleHide = () => {
        hide(true);
    };

    if (hidden) return null;

    return (
        <Stack gap={2} maxWidth={"1280px"}>
            <WhiteBox p={3}>
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    divider={<Divider orientation="vertical" flexItem />}
                    gap={3}
                >
                    <Stack direction={"column"} gap={0.5} flex={1}>
                        <Typography variant="h5">
                            {t("bred.synthesis.no_data_previous_period", { year: previousCampaign?.label })}
                        </Typography>
                        <Typography variant="small">{t("bred.synthesis.compare_both_periods")}</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} flex={1}>
                        <Button onClick={handleHide}>{t("bred.synthesis.no_data_for_this_period")}</Button>
                        <Button onClick={handleNavigatePreviousCampaign} variant="ternary">
                            {t("bred.synthesis.go_to_previous_period")}
                        </Button>
                    </Stack>
                </Stack>
            </WhiteBox>
        </Stack>
    );
};
