import { Fragment } from "react";
import { Navigate, Outlet, Route, isRouteErrorResponse, useRouteError } from "react-router-dom";
import { IngredientList } from "./IngredientsList";
import { CreateIngredientPage } from "./IngredientForm/CreateIngredient";
import { UpdateQuestionnaire } from "./IngredientForm/UpdateIngredient";
import { useTranslation } from "react-i18next";
import { QuestionnaireUpdate } from "./IngredientDetails/Questionnaire/QuestionnaireUpdate/QuestionnaireUpdate";
import { IngredientContextProvider } from "./IngredientDetails/ExistingIngredientContext";
import { IngredientDetails } from "./IngredientDetails/IngredientDetails";
import { Forbiddenpage } from "src/components/PageState/ForbiddenPage";
import { CREATE, INGREDIENT, LIST, MODIFY, useFoodpilotContext, VIEW } from "src/context/FoodpilotContext";
import { Slugs } from "src/context/ProtectedContext";

export const useIngredientRouter = () => {
    return (
        <Fragment>
            <Route path="ingredients" errorElement={<IngredientError />}>
                <Route element={<IngredientActionProtection slug={LIST} />}>
                    <Route index element={<IngredientList />} />
                </Route>

                <Route path={"new"} element={<IngredientActionProtection slug={CREATE} />}>
                    <Route element={<CreateIngredientPage />}>
                        <Route index />
                        <Route path={":startingPageId"} />
                    </Route>
                </Route>

                <Route path=":ingredientId" element={<ExistingIngredientLayout />}>
                    <Route path={"edit"} element={<IngredientActionProtection slug={MODIFY} />}>
                        <Route element={<UpdateQuestionnaire />}>
                            <Route index />
                            <Route path={":startingPageId"} />
                        </Route>
                    </Route>

                    <Route path="details" element={<IngredientActionProtection slug={VIEW} />}>
                        <Route index element={<Navigate to="questionnaire" replace />} />

                        <Route path={"scores"}>
                            <Route index element={<IngredientDetails section={"scores"} />} />
                        </Route>
                        <Route path={"questionnaire"}>
                            <Route index element={<IngredientDetails section={"questionnaire"} />} />
                        </Route>
                        <Route path={"activities"}>
                            <Route index element={<IngredientDetails section={"activities"} />} />
                        </Route>
                        <Route path={"informations"}>
                            <Route index element={<IngredientDetails section={"informations"} />} />
                        </Route>

                        <Route path="questionnaire">
                            <Route path="edit/:headingId" element={<QuestionnaireUpdate />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Fragment>
    );
};

type IngredientActionProtectionProps = {
    slug: Slugs;
};
const IngredientActionProtection = (props: IngredientActionProtectionProps) => {
    const { slug } = props;
    const { canUser } = useFoodpilotContext();

    if (canUser(slug, INGREDIENT) === false) {
        return <Forbiddenpage />;
    }

    return <Outlet />;
};

const ExistingIngredientLayout = () => {
    return (
        <IngredientContextProvider>
            <Outlet />
        </IngredientContextProvider>
    );
};

const IngredientError = () => {
    const error = useRouteError();
    const { t } = useTranslation();

    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return <div>{t("Page inexistante")} 404</div>;
        } else if (error.status === 401) {
            return <div>{t("Unauthorized")}: 401</div>;
        } else if (error.status === 503) {
            return <div>API down: 503</div>;
        } else if (error.status === 500) {
            return <div>API error: 500</div>;
        }
    }
    console.error(error);
    return <div>Hello this is an Error from Ingredient</div>;
};
