import { Route, Navigate, Outlet } from "react-router-dom";
import { useProductRouter } from "../Product/productRouter";
import { useIngredientRouter } from "../Ingredient/ingredientRouter";
import { useSupplierRouter } from "../Supplier/supplierRouter";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UnderConstruction } from "../../components/UnderConstruction";
import { useClientSwitch } from "src/components/ClientSwitch/useClientSwitch";
import { CompanyTabs } from "./CompanyTabs";
import { companySlug } from "src/services/URLHandler";
import { RouterClientSwitch } from "src/components/ClientSwitch/RouterClientSwitch";
import { QuestionnaireUpdate } from "./Questionnaire/QuestionnaireUpdate/QuestionnaireUpdate";

export const useCompanyRouter = () => {
    const productRouter = useProductRouter();
    const ingredientRouter = useIngredientRouter();
    const supplierRouter = useSupplierRouter();
    const clientSwitch = useClientSwitch();

    const cs_defaultRedirect = clientSwitch({
        defaultOption: <Navigate to="questionnaire" replace />,
        bred: <Navigate to="synthese" replace />,
        collective: <Navigate to="scores" replace />,
    });

    return (
        <Route path={`${companySlug}/:companyId`}>
            <Route index element={cs_defaultRedirect} />
            <Route path="scores" element={<RouterClientSwitch whitelistedMode={["collective"]} />}>
                <Route index element={<CompanyTabs section="scores" />} />
            </Route>

            <Route path="synthese" element={<RouterClientSwitch whitelistedMode={["bred"]} />}>
                <Route index element={<CompanyTabs section="synthese" />} />
            </Route>

            <Route path="questionnaire" element={<RouterClientSwitch whitelistedMode={["bred", "collective"]} />}>
                <Route index element={<CompanyTabs section="questionnaire" />} />
                <Route path="edit/:headingId" element={<QuestionnaireUpdate />} />
            </Route>

            <Route path="requirements" element={<RouterClientSwitch whitelistedMode={["bred"]} />}>
                <Route index element={<CompanyTabs section="exigences" />} />
            </Route>

            <Route path="action-plan" element={<ActionPlan />} />

            {productRouter}
            {ingredientRouter}
            {supplierRouter}
        </Route>
    );
};

const ActionPlan = () => {
    const { t } = useTranslation();
    return (
        <Box sx={{ padding: "40px 52px" }}>
            <h1>{t("Plan d'action")}</h1>
            <UnderConstruction />
        </Box>
    );
};
