import { MenuItem, Select, Stack, Typography } from "@mui/material";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { DataListOption } from "src/api/sure-api/ssq/useGetOneSsq";
import { FormPropertyProps } from "../../FormProperty";
import { FoodsTreeview } from "@foodpilot/foods";

type SelectTreeViewProps = FormPropertyProps & {
    groupedOptions: Partial<Record<string, DataListOption[]>>;
    options: DataListOption[];
};

const itemIdFromOption = (option: DataListOption): string => `${option.groupOption}-${option.value}-${option.position}`;

export const SelectTreeView = (props: SelectTreeViewProps) => {
    const { t } = useTranslation();
    const { groupedOptions, post, property, options, element } = props;

    const defaultValue = property.defaultValue ?? "";
    const initialValue = element && !property.readonly ? element.value ?? "" : defaultValue;
    const _selectedOption = options.find((option) => option.value === initialValue) ?? null;
    const selectedOption = useRef(_selectedOption);

    if (post === undefined) return;

    const selectedValue = selectedOption.current?.label ? itemIdFromOption(selectedOption.current) : t("No selection");

    return (
        <Stack direction="column" gap="8px">
            <Select
                sx={{ borderRadius: "24px", width: "280px", fontSize: "12px" }}
                value={selectedValue}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                    PaperProps: {
                        sx: {
                            mt: "13px",
                            maxHeight: "200px",
                            px: "28px",
                            py: "24px",
                            borderRadius: "12px",
                            width: "30%",
                            "& .MuiMenuItem-root": {},
                        },
                    },
                }}
            >
                {/* Hack to make MUI Select work with something else than MenuItem.
                 *  This is bad, but we need it ASAP
                 */}
                {options.map((o, i) => (
                    <MenuItem sx={{ display: "none" }} key={i} value={itemIdFromOption(o)}>
                        {o.label}
                    </MenuItem>
                ))}
                <FoodsTreeview
                    defaultSelectedId={selectedValue}
                    items={Object.keys(groupedOptions).map((k) => ({
                        id: k,
                        label: `${k}`,
                        children: groupedOptions[k]!.map((o) => ({
                            id: itemIdFromOption(o),
                            label: o.label,
                        })),
                    }))}
                    onSelectedItem={(itemId) => {
                        const option = options.find((option) => itemIdFromOption(option) === itemId);
                        selectedOption.current = option!;
                        option &&
                            props.onUpdate({
                                postId: post.id,
                                propertyId: property.id,
                                value: option.value,
                                position: Number(props.position ?? 0),
                            });
                    }}
                />
            </Select>
        </Stack>
    );
};
