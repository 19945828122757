import { Box, Stack, Typography, CircularProgress } from "@mui/material";
import { LinearProgress } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";

type QuestionnairesListHeaderProps = {
    title?: string;
    isSsqLoading: boolean;
    isPostLoading: boolean;
    headingsCompletions: number[];
};
export const QuestionnairesListHeader = (props: QuestionnairesListHeaderProps) => {
    const { t } = useTranslation();
    const { title, isSsqLoading, isPostLoading, headingsCompletions } = props;

    const completions: number = headingsCompletions.reduce((a: number, b: number) => a + b, 0);
    const total = headingsCompletions.length ? Math.round(completions / headingsCompletions.length) : 0;

    if (!title) {
        return (
            <Stack width="304px" direction="column" alignItems="flex-start" gap="4px">
                <Typography variant="h4">{t("Complétion totale")} :</Typography>
                <Box width="100%">
                    {isSsqLoading || isPostLoading ?
                        <CircularProgress size="18px" />
                    :   <LinearProgress value={total} />}
                </Box>
            </Stack>
        );
    }

    return (
        <Stack width="100%" maxWidth="369px" gap="8px">
            <Typography variant="h3">{title}</Typography>
            <Stack direction="row" alignItems="center" gap="12px">
                <Typography variant="body">{t("Complétion totale")} :</Typography>
                <Box flex={1}>
                    {isSsqLoading || isPostLoading ?
                        <CircularProgress size="18px" />
                    :   <LinearProgress value={total} />}
                </Box>
            </Stack>
        </Stack>
    );
};
