import { createContext, useContext } from "react";
import { Settings } from "src/api/client-api/settings/useSettings";
import { Score } from "src/types";
import { ScoreType } from "src/api/client-api/scoreType/useGetAllScoreType";
import { Campaign } from "src/api/client-api/useCampaign";
import { Slugs, Entities } from "./ProtectedContext";
import { User, Company } from "src/api/client-api/useUserNew";

// To avoid breaking change. But this interface is useless
export type UserContext = {
    trustedUser: User;
};
export type FoodpilotContextInitialValues = {
    user: UserContext;
    settings: Settings | undefined;
    scoreTypes: ScoreType[] | undefined;
    scores: Score[] | undefined;
    campaigns: Campaign[];
    setCurrentCampaign: (value: Campaign | null) => void;
    currentCampaign: Campaign | null;
    canUser: (slug: Slugs, entity: Entities) => boolean;
    selectedCompany: Company | null;
    selectedCompanyVariations: Record<number, number>;
    areCompaniesRefetching: boolean;
    setSelectedCompanyVariations: (value: Record<number, number>) => void;
    isUserOfTypeSupplier: () => boolean;
    getScoresByType: (type: ScoreType["type"]) => Score[];
};

// Entities
export const PRODUCT = "App\\Entity\\Product";
export const INGREDIENT = "App\\Entity\\Ingredient";
export const COMPANY = "App\\Entity\\Company";

// Slugs
export const VIEW = "view";
export const LIST = "list";
export const CREATE = "create";
export const MODIFY = "modify";
export const DELETE = "delete";

export const FoodpilotContext = createContext<FoodpilotContextInitialValues | null>(null);

export const useFoodpilotContext = () => {
    const context = useContext(FoodpilotContext);

    if (context) {
        return context;
    }

    throw new Error(`useProductListContext must be used within an ProtectedContextProvider`);
};
