import { MRT_ColumnDef, createMRTColumnHelper } from "material-react-table";
import { CircularProgress } from "@mui/material";
import {
    IngredientReference,
    useGetAllIngredientReference,
} from "src/api/sure-api/ingredientReference/useGetAllIngredientReference";
import { useTranslation } from "react-i18next";
import { GridText, GridTextList } from "src/components/Grid/GridText";
import { IngredientList } from "src/api/client-api/ingredients/useGetAllIngredients";
import { useNavigate } from "react-router-dom";
import { MrtColumns, extendColumnsByScores } from "src/utils/useGridColumns";
import { GridLink } from "src/components/Grid/GridLink";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { URLHandler } from "src/services/URLHandler";

export type MrtColumnsIngredient = {
    mrt_ingredientColumns: MrtColumns<IngredientList>;
    refIngIdToRefIng: Record<number, IngredientReference> | undefined;
};

export const useIngredientColumns = (isRefetching: boolean = false): MrtColumnsIngredient => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { selectedCompany, isUserOfTypeSupplier, getScoresByType } = useFoodpilotContext();
    const { ingredientsReference } = useGetAllIngredientReference();
    const ingredientsScores = getScoresByType("ingredient");

    const refIngIdToRefIng = ingredientsReference?.reduce(
        (acc, ing) => {
            acc[ing.id] = ing;
            return acc;
        },
        {} as Record<number, IngredientReference>,
    );

    const columnHelper = createMRTColumnHelper<IngredientList>();

    const noValue = "-";
    const mrt_ingredientColumns: MRT_ColumnDef<IngredientList, string>[] = [];
    mrt_ingredientColumns.push(
        columnHelper.accessor("name", {
            header: t("Nom"),
            Cell: (value) =>
                value.row.original.canView ?
                    <GridLink
                        sx={{ color: (theme) => theme.palette.primary[700] }}
                        onClick={() => {
                            if (selectedCompany === null) return;
                            const ingredientId = value.row.original.id;
                            const url = URLHandler.ingredient.scores(selectedCompany.id, ingredientId);
                            navigate(url);
                        }}
                    >
                        {value.renderedCellValue}
                    </GridLink>
                :   <GridText value={value.renderedCellValue}></GridText>,
            grow: false,
        }),
    );

    if (!isUserOfTypeSupplier()) {
        mrt_ingredientColumns.push(
            columnHelper.accessor((row) => row.clientId || noValue, {
                id: "clientId",
                header: t("Identifiant unique"),
                Cell: (value) => <GridText value={value.cell.getValue()} />,
            }),
        );
    }

    mrt_ingredientColumns.push(
        columnHelper.accessor("ingredientReferenceId", {
            header: t("Référence"),
            enableSorting: false,
            Cell: (value) => {
                const refId = value.cell.getValue();

                if (!refId) return <GridText value={noValue} />;
                if (!refIngIdToRefIng) return <CircularProgress size="18px" />;

                const ingToShow = refIngIdToRefIng[refId];

                return <GridText value={ingToShow?.name ?? noValue} />;
            },
        }) as MRT_ColumnDef<IngredientList, string>,
    );
    mrt_ingredientColumns.push(
        columnHelper.accessor((row) => row.ingredientLine?.name ?? noValue, {
            id: "ingredientLine.name",
            header: t("Filière"),
            Cell: (value) => {
                const v = value.cell.getValue();
                if (Array.isArray(v)) {
                    return <GridTextList value={v} separator=", " />;
                }

                return <GridText value={value.renderedCellValue} />;
            },
        }),
    );

    extendColumnsByScores(mrt_ingredientColumns, ingredientsScores, mrt_ingredientColumns.length, isRefetching);

    if (!isUserOfTypeSupplier()) {
        mrt_ingredientColumns.push(
            columnHelper.accessor("ingredientSuppliers", {
                header: "Fournisseurs",
                enableSorting: false,
                Cell: (value) => {
                    const ingSupplier = value.cell.getValue();
                    if (Array.isArray(ingSupplier)) {
                        return <GridTextList value={ingSupplier.map((item) => item.supplier.name)} separator=", " />;
                    }

                    return <GridText value={value.renderedCellValue} />;
                },
            }) as MRT_ColumnDef<IngredientList, string>,
        );
    }

    return { mrt_ingredientColumns, refIngIdToRefIng };
};
