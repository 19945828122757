import { AccordionContent, FoodsIcon, FoodsIllustrations, WhiteBox } from "@foodpilot/foods";
import {
    Accordion as MuiAccordion,
    AccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    Box,
    Button,
    Divider,
    Stack,
    Typography,
    AccordionProps,
    accordionClasses,
    styled,
    AccordionSummaryProps,
    accordionSummaryClasses,
    Pagination,
    PaginationItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { useGetCompanyPost } from "src/api/client-api/post/useGetCompanyPost";
import { useGetOneSsq } from "src/api/sure-api/ssq/useGetOneSsq";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { getHeadingsCompletions, getVisibleHeadings } from "src/utils/ssq";
import { URLHandler } from "src/services/URLHandler";
import { useState } from "react";

type Requirement = Omit<AccordionContent, "title"> & {
    completed: boolean;
    title: string;
    category: string;
};

// TMP to display something
const PAGES: Requirement[] = [
    {
        completed: true,
        title: "Mise en place d'un plan de mobilité employeur",
        content: (
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium, hic nam debitis ratione illum
                omnis deleniti soluta, ullam ipsum sequi, tempore eius fuga fugit est voluptatem ab illo deserunt quasi!
            </p>
        ),
        category: "Mobilité & transports",
        onOpenAction: () => {},
    },
    {
        completed: false,
        title: "Respect du décret",
        content: (
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium, hic nam debitis ratione illum
                omnis deleniti soluta, ullam ipsum sequi, tempore eius fuga fugit est voluptatem ab illo deserunt quasi!
            </p>
        ),
        category: "Immobilier",
        onOpenAction: () => {},
    },
];

const Accordion = styled((props: AccordionProps) => <MuiAccordion {...props} />)(({ theme }) => ({
    [`&.${accordionClasses.root}`]: {
        border: `1px solid ${theme.custom.grey[500]}`,
        borderRadius: "8px",
        marginBottom: "20px",
    },
    "&::before": {
        display: "none",
    },
    [`&.${accordionClasses.expanded} .${accordionSummaryClasses.root}`]: {
        borderBottom: `1px solid ${theme.custom.grey[500]}`,
        borderRadius: "initial",
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
    [`&.${accordionSummaryClasses.root}:hover`]: {
        backgroundColor: theme.custom.grey[400],
        borderRadius: "8px",
    },
    [`&.${accordionSummaryClasses.expanded}:hover`]: {
        backgroundColor: theme.custom.grey[400],
        borderBottomRadius: "0",
    },
    [`&.${accordionSummaryClasses.root}:hover .category`]: {
        backgroundColor: theme.custom.grey[100],
    },
}));

export const LegalRequirements = () => {
    const { currentCampaign, selectedCompany } = useFoodpilotContext();
    const navigate = useNavigate();
    const { post } = useGetCompanyPost(selectedCompany?.id, currentCampaign?.id);
    const ssqId = selectedCompany?.companySector?.ssqId;
    const { ssq } = useGetOneSsq(ssqId ?? 0);
    const headings = ssq ? getVisibleHeadings(ssq) : [];
    const headingsCompletions: number[] = getHeadingsCompletions(headings, post);
    const completions = headingsCompletions.reduce((a: number, b: number) => a + b, 0);
    const total = headingsCompletions.length ? Math.round(completions / headingsCompletions.length) : 0;

    // for test purpose
    const noRequirement = false;

    if (noRequirement) {
        return <NoRequirement />;
    }

    if (total === 0 && headings[0]) {
        return (
            <Stack maxWidth={"1280px"}>
                <QuestionnaireNeeded companyId={selectedCompany?.id} headingId={headings[0].id} />
            </Stack>
        );
    }

    const handleCompleteQuestionnaireClick = () => {
        if (selectedCompany === null) return;
        if (headings.length === 0) return;

        const headingId = headings[0].id;
        const url = URLHandler.company.ssqHeading(selectedCompany.id, headingId);
        navigate(url);
    };

    return (
        <Stack gap={3.5}>
            <InfoBox />
            <Stack sx={{ py: 3, backgroundColor: "white" }} borderRadius={2} divider={<Divider />} gap={2}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} px={4}>
                    <Stack direction={"row"} alignItems={"baseline"}>
                        <Typography variant="h3">{t("bred.requirement")}</Typography>
                        <Typography variant="huge">&nbsp;-&nbsp;{PAGES.length}</Typography>
                    </Stack>
                    <Button variant="primary" onClick={handleCompleteQuestionnaireClick}>
                        {t("questionnaire.modify_answers")}
                    </Button>
                </Stack>
                <Box px={4}>
                    {PAGES.map((p, i) => {
                        return (
                            <Accordion key={i} elevation={0}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Stack direction={"row"} alignItems={"baseline"} gap={2}>
                                        <Typography variant="h4">{p.title}</Typography>
                                        <Typography
                                            className="category"
                                            sx={{ backgroundColor: (theme) => theme.custom.grey[400] }}
                                            px={1}
                                            borderRadius={0.5}
                                            variant="small-bold"
                                        >
                                            {p.category}
                                        </Typography>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails sx={{ maxWidth: "50%" }}>
                                    <Stack>
                                        <Box>{p.content}</Box>
                                        <Button variant="ternary" sx={{ width: "fit-content" }}>
                                            <Stack direction={"row"} gap={1} alignItems={"center"}>
                                                <Typography>En savoir</Typography>
                                                <FoodsIcon icon="openNewTab" size={1} />
                                            </Stack>
                                        </Button>
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                    <Stack direction={"row"} justifyContent={"end"}>
                        <Pagination
                            count={10}
                            color="primary"
                            renderItem={(item) => (
                                <PaginationItem
                                    slots={{
                                        previous: () => <FoodsIcon icon="arrowLeft" size={1} />,
                                        next: () => <FoodsIcon icon="arrowRight" size={1} />,
                                    }}
                                    {...item}
                                />
                            )}
                        />
                    </Stack>
                </Box>
            </Stack>
        </Stack>
    );
};

const NoRequirement = () => (
    <Stack maxWidth={"1280px"}>
        <WhiteBox>
            <Stack display={"flex"} justifyContent={"center"} alignItems={"center"} p={6} gap={3}>
                <Box
                    sx={{
                        borderRadius: "9999px",
                        p: 2,
                        backgroundColor: (theme) => theme.custom.green[100],
                        width: "56px",
                        height: "56px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                    }}
                >
                    <Box mt={0}>
                        <FoodsIcon icon="check" size={3} />
                    </Box>
                </Box>
                <Typography variant="h4">Aucune exigence règlementaire spécifique identifiée</Typography>
            </Stack>
        </WhiteBox>
    </Stack>
);
const InfoBox = () => {
    const [hidden, hide] = useState(false);
    if (hidden) return null;

    const handleClick = () => {
        hide(true);
    };

    return (
        <Stack sx={{ backgroundColor: "white", borderRadius: "8px" }} px={3} py={2.5}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"} alignItems={"center"} gap={2.5}>
                    <Stack>
                        <FoodsIcon icon="information" size={3} />
                    </Stack>
                    <Typography variant="h5">{t("bred.requirement.info")}</Typography>
                </Stack>
                <Button onClick={handleClick}>J&apos;ai compris</Button>
            </Stack>
        </Stack>
    );
};

type QuestionnaireNeededProps = {
    companyId?: number;
    headingId: number;
};

export const QuestionnaireNeeded = (props: QuestionnaireNeededProps) => {
    const { companyId, headingId } = props;
    const navigate = useNavigate();
    const handleClick = () => {
        if (!companyId) return;
        const url = URLHandler.company.ssqHeading(companyId, headingId);
        navigate(url);
    };

    return (
        <WhiteBox sx={{ p: 3 }}>
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                divider={<Divider orientation="vertical" flexItem />}
            >
                <Stack flex={3} direction={"row"} gap={2} justifySelf={"start"}>
                    <FoodsIllustrations illustration="questionnaire" />
                    <Stack justifyContent={"center"} gap={1}>
                        <Typography variant="body-bold">
                            {t("bred.requirements.info.complete_questionnaire")}
                        </Typography>
                        <Typography variant="body">{t("bred.requirements.info.questionnaire_completed")}</Typography>
                    </Stack>
                </Stack>
                <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
                    <Button variant="primary" onClick={handleClick}>
                        {t("questionnaire.complete-questionnaire")}
                    </Button>
                </Stack>
            </Stack>
        </WhiteBox>
    );
};
