import React, { Fragment } from "react";
import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, TextField } from "@mui/material";
import { z } from "zod";
import { useNavigate, useRevalidator } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";

import { Loading } from "src/components/utils/Loading";
import { useCreateRole } from "src/api/client-api/roles/useCreateRole.ts";
import { useGetAllPermissions } from "src/api/client-api/permissions/useGetAllPermissions.ts";
import { entityToObjectName } from "src/pages/Settings/Roles/List.tsx";
import { Role } from "src/api/client-api/roles/useGetRole.ts";
import { useSnackbar } from "src/utils/useSnackbar";
import { URLHandler } from "src/services/URLHandler";

export type RoleCreateProps = {
    existingRole?: Role;
};
export const RoleCreate = (props: RoleCreateProps) => {
    const { existingRole } = props;

    const { allPermissions, isLoading } = useGetAllPermissions();

    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const revalidator = useRevalidator();
    const { t } = useTranslation();

    const createRole = useCreateRole();
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<RoleWrite>({
        mode: "onChange",
        resolver: zodResolver(RoleWriteSchema),
    });

    const onSubmitHandler: SubmitHandler<RoleWrite> = (values) => {
        const { code, permissions } = values;
        createRole.mutate(
            {
                isNew: existingRole === undefined,
                role: {
                    code: existingRole ? existingRole.code : code,
                    permission: Object.keys(permissions).filter((key) => values.permissions[key]),
                },
            },
            {
                onSuccess: () => {
                    revalidator.revalidate();
                    navigate(URLHandler.settings.roles.list());
                },
                onError: (error) => {
                    snackbar.forceClose();
                    snackbar.setSnackbarProps({
                        type: "error",
                        message: "Couldn't create the role.",
                    });
                },
            },
        );
    };

    if (isLoading) {
        return <Loading />;
    }

    const permissionsGrouped = Object.groupBy(allPermissions || [], (permission) => permission.object);
    const existingPermissions = (existingRole?.permission || []).map((permission) => permission["@id"]);

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <h1>{existingRole ? t("Role") : t("settings.roles.addRole")}</h1>

                <Controller
                    name={"code"}
                    control={control}
                    defaultValue={existingRole?.code || ""}
                    render={({ field: { ref, ...field } }) => (
                        <FormControlLabel
                            control={
                                <TextField
                                    type="text"
                                    sx={{ marginLeft: "16px" }}
                                    error={Boolean(errors.code)}
                                    helperText={errors.code?.message}
                                    disabled={Boolean(existingRole)}
                                    inputRef={ref}
                                    {...field}
                                />
                            }
                            label={t("settings.roles.roleName")}
                            labelPlacement={"start"}
                            sx={{ marginLeft: 0 }}
                        />
                    )}
                />

                <div>
                    {Object.entries(permissionsGrouped).map(([object, permissions]) => {
                        return (
                            <Fragment key={object}>
                                <h2>{entityToObjectName(object)}</h2>
                                <FormGroup row>
                                    {permissions?.map((permission) => {
                                        return (
                                            <Controller
                                                key={permission["@id"]}
                                                name={`permissions.${permission["@id"]}`}
                                                control={control}
                                                defaultValue={existingPermissions.includes(permission["@id"])}
                                                render={({ field: { ref, ...field } }) => (
                                                    <FormControlLabel
                                                        key={permission.slug}
                                                        control={
                                                            <Checkbox
                                                                defaultChecked={existingPermissions.includes(
                                                                    permission["@id"],
                                                                )}
                                                                inputRef={ref}
                                                                {...field}
                                                            />
                                                        }
                                                        label={permission.slug}
                                                    />
                                                )}
                                            />
                                        );
                                    })}
                                </FormGroup>
                            </Fragment>
                        );
                    })}
                </div>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Button sx={{ marginTop: "1rem", width: "10rem" }} variant="primary" type="submit">
                        {t("Enregistre")}
                    </Button>
                </Box>
            </form>
        </Container>
    );
};
type RoleWrite = {
    code: string;
    permissions: Record<string, boolean>;
};
const RoleWriteSchema: z.ZodSchema<RoleWrite> = z.object({
    code: z.string().startsWith("ROLE_", "Role must start with ROLE_"),
    permissions: z.record(z.boolean()),
});
