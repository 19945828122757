import { useState } from "react";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { FormPropertyProps } from "../FormProperty";
import { FoodsRadioList, WhitePopover } from "@foodpilot/foods";
import { DataListOption } from "src/api/sure-api/ssq/useGetOneSsq";
import { FormPropertyHint } from "src/components/FormProperty/FormPropertyHint.tsx";

export const Radio = (props: FormPropertyProps) => {
    const theme = useTheme();
    const { property, post, element, canEdit = true, isSubproperty } = props;

    console.log(property.dataList);

    const options =
        property.dataList?.options.sort(
            (optionA: DataListOption, optionB: DataListOption) => optionA.position - optionB.position,
        ) ?? [];

    const defaultValue = property.defaultValue ?? "";
    const _initialValue = element && !property.readonly ? element.value ?? "" : defaultValue;
    const initialValue = options.find((option: DataListOption) => option.value === _initialValue);

    const [value, setValue] = useState<DataListOption | undefined>(initialValue);

    if (!post) return <CircularProgress />;

    const radioList = (
        <FoodsRadioList
            values={options}
            selectedItem={value}
            onChange={(newValue: DataListOption) => {
                setValue(newValue);

                props.onUpdate({
                    postId: post.id,
                    propertyId: Number(property.id),
                    value: newValue.value,
                    position: Number(props.position ?? 0),
                });
            }}
            getId={(option: DataListOption) => option.value}
            getName={(option: DataListOption) => option.label}
            FormControlProps={{
                sx: {
                    flexDirection: "column",
                },
            }}
            FormControlLabelProps={{
                sx: {
                    gap: "3px",
                    marginRight: "28px",
                    ".MuiTypography-root": {
                        ...theme.typography.body,
                    },
                },
            }}
            RadioProps={{
                disabled: !canEdit || property.readonly,
            }}
        />
    );

    return property.hint && isSubproperty ?
            <WhitePopover icon={radioList} body={<FormPropertyHint hint={property.hint} />} size="260px" />
        :   radioList;
};
