import { ReactNode, createContext, useContext, useEffect, Dispatch, SetStateAction, useState } from "react";
import { useParams } from "react-router-dom";
import { Ingredient, useGetOneIngredient } from "src/api/client-api/ingredients/useGetOneIngredient";
import { useGetIngredientPosts } from "src/api/client-api/post/useGetIngredientPosts";
import { useGetOneIngredientReference } from "src/api/sure-api/ingredientReference/useGetOneIngredientReference";
import { useGetOneSsq } from "src/api/sure-api/ssq/useGetOneSsq";
import { Post } from "src/api/client-api/post/postSchema";
import { Ssq } from "src/api/sure-api/ssq/useGetOneSsq";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { IngredientReference } from "src/api/sure-api/ingredientReference/useGetAllIngredientReference";

type IngredientContextInitialValues = {
    ingredient: Ingredient | undefined;
    ingredientReference: IngredientReference | undefined;
    variations: Record<number, number>;
    setVariations: (variations: Record<number, number>) => void;
    isLoading: boolean;
    isRefetching: boolean;

    ssq: Ssq | undefined;
    isIngRefLoading: boolean;
    isPostsLoading: boolean;
    posts: Post[] | undefined;

    activeSupplierId: number;
    setActiveSupplierId: Dispatch<SetStateAction<number>>;

    fetchPosts: () => void;
};
const IngredientContext = createContext<IngredientContextInitialValues | null>(null);

type IngredientContextProviderProps = { children: ReactNode };
export const IngredientContextProvider = (props: IngredientContextProviderProps) => {
    const { children } = props;
    const { ingredientId } = useParams();

    const { ...ingredientsData } = useAllIngredientData(ingredientId ?? "Completely Bad Id");

    const [activeSupplierId, setActiveSupplierId] = useState(0);

    const items: IngredientContextInitialValues = {
        ...ingredientsData,
        activeSupplierId,
        setActiveSupplierId,
    };

    return <IngredientContext.Provider value={items}>{children}</IngredientContext.Provider>;
};

export const useIngredientContext = () => {
    const context = useContext(IngredientContext);

    if (context) {
        return context;
    }

    throw new Error(`useIngredientContext must be used within an IngredientContextProvider`);
};

const useAllIngredientData = (ingredientId: string) => {
    const [variations, setVariations] = useState<Record<number, number>>({});
    const defaultBadId = -1;

    const { currentCampaign } = useFoodpilotContext();
    const { ingredient, isFetched, isLoading, isRefetching } = useGetOneIngredient(
        ingredientId ?? "Completely Bad Id",
        {
            campaign: currentCampaign?.id,
            dimensions: [["suppliers"]],
            variations: variations,
        },
    );

    const {
        ingredientReference,
        refetch: fetchIngRef,
        isFetched: isIngRefFetched,
        isLoading: isIngRefLoading,
    } = useGetOneIngredientReference(ingredient?.ingredientReferenceId ?? defaultBadId, { enabled: false });

    const {
        ssq,
        refetch: fetchSsq,
        isLoading: isPostsLoading,
    } = useGetOneSsq(ingredientReference?.ssq?.id ?? defaultBadId, { enabled: false });

    const { posts, refetch: fetchPosts } = useGetIngredientPosts(ingredient?.id ?? defaultBadId, currentCampaign?.id);

    useEffect(() => {
        if (isFetched === true && ingredient !== undefined) {
            if (ingredient.ingredientReferenceId !== undefined) {
                fetchIngRef();
            }

            fetchPosts();
        }
    }, [ingredient, isFetched]);

    useEffect(() => {
        if (isIngRefFetched === true && ingredientReference !== undefined) {
            if (ingredientReference.ssq?.id !== undefined) {
                fetchSsq();
            }
        }
    }, [ingredientReference, isIngRefFetched]);

    return {
        ingredient,
        isLoading,
        isRefetching,
        ingredientReference,
        variations,
        setVariations,
        ssq,
        posts,
        isIngRefLoading,
        isPostsLoading,
        fetchPosts,
    };
};
