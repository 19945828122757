import { useTranslation } from "react-i18next";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { WhitePopover } from "@foodpilot/foods";
import DefaultValueIcon from "src/assets/icons/default-value.svg?react";

export type DefaultValueButtonProps = {
    onClick: () => void;
};
export const DefaultValueButton = (props: DefaultValueButtonProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const DefaultValueButtonElement = (
        <Stack
            width="36px"
            height="36px"
            alignItems="center"
            justifyContent="center"
            borderRadius="100%"
            marginLeft="8px"
            onClick={props.onClick}
            sx={{
                ":hover": {
                    background: theme.custom.grey[500],
                    cursor: "pointer",
                },
                ":active": {
                    background: theme.custom.grey[700],
                },
            }}
        >
            <DefaultValueIcon />
        </Stack>
    );

    const DefaultValuePopoverBody = (
        <Box padding="8px" textAlign="center">
            <Typography variant="body">{t("Réinitialiser la valeur par défaut")}</Typography>
        </Box>
    );

    return <WhitePopover icon={DefaultValueButtonElement} body={DefaultValuePopoverBody} size="240px" />;
};
