import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Typography, useTheme, CircularProgress } from "@mui/material";
import { FormPropertyProps } from "../FormProperty";
import { FormUnit, RoundInput, WhitePopover } from "@foodpilot/foods";
import { formatNumber, formatUnitAbbreviation } from "src/utils/formatting";
import { DefaultValueButton } from "../DefaultValueButton";
import { FormPropertyHint } from "src/components/FormProperty/FormPropertyHint.tsx";
import { getFormattedScoreByScoreId } from "src/utils/ssq";
import { useScoreContext } from "src/context/ScoreContext";

export const Numeric = (props: FormPropertyProps) => {
    const { t, i18n } = useTranslation();
    const { displayedScoreId, displayedVariationId, formattedScores } = useScoreContext();

    const theme = useTheme();
    const { property, post, element, canEdit = true, isSubproperty, isMultivalued } = props;

    const defaultValue = (property.defaultValue ?? "").replace(/\s/g, "").replace(/,/g, ".");
    const formattedDefaultValue = formatNumber(defaultValue, i18n.resolvedLanguage, { maximumFractionDigits: 20 });
    const initialValue =
        element && !property.readonly ? (element.value ?? "").replace(/\s/g, "").replace(/,/g, ".") : defaultValue;

    const formattedScore = getFormattedScoreByScoreId(formattedScores, displayedScoreId);
    const propertyScore = formattedScore?.properties?.[property.id];

    const scoreVariations = formattedScore?.scoreVariations ?? {};
    const scoreVariation = scoreVariations?.[displayedVariationId ?? 0];

    const propertyVariations = propertyScore?.variations ?? {};
    const propertyVariation = propertyVariations?.[displayedVariationId ?? 0];

    const [previousValue, setPreviousValue] = useState(initialValue);
    const [value, setValue] = useState(initialValue);

    const [error, setError] = useState(false);

    if (!post) return <CircularProgress />;

    const unit = formatUnitAbbreviation(property.unit);

    useEffect(() => {
        if (value === "") {
            return setError(false);
        }
        const v = Number(value);

        const isBelowMin = property.minValue ? v < property.minValue : false;
        const isAboveMax = property.maxValue ? v > property.maxValue : false;
        setError(isBelowMin || isAboveMax);
    }, [value]);

    const updatePropertyValue = (value: string) => {
        if (error) {
            return setValue(previousValue);
        }

        props.onUpdate({
            postId: post.id,
            propertyId: Number(property.id),
            value: value,
            position: Number(props.position ?? 0),
        });
    };

    const isValidNumber = (value: any) => {
        return typeof value === "number" && !isNaN(value);
    };

    const roundInput = (
        <RoundInput
            type="number"
            value={value !== "_empty_" ? value : ""}
            error={error}
            onChange={(e) => setValue(e.target.value)}
            onFocus={(e) => {
                const isFocusDirect = !e.relatedTarget;

                if (!isFocusDirect) {
                    return;
                }

                setPreviousValue(value);
            }}
            onBlur={(e) => {
                const relatedTarget = e.nativeEvent.relatedTarget as Element;
                const isTargetPopover = relatedTarget && relatedTarget.classList.contains("MuiPopover-paper");

                if ((e.nativeEvent.type === "focusout" && isTargetPopover) || value === previousValue) {
                    return;
                }

                updatePropertyValue(value);
            }}
            disabled={property.readonly || canEdit === false}
            sx={{
                minWidth: "240px",
                height: "40px",
                borderRadius: "40px",
                borderColor: theme.custom.grey[500],
                ":hover": {
                    backgroundColor: theme.custom.grey[300],
                },
                "&.Mui-focused": {
                    outline: `2px solid ${theme.custom.grey[2800]}`,
                },
                "&.Mui-disabled": {
                    background: theme.custom.grey[500],
                    color: theme.custom.grey[2000],
                },
            }}
            // Disable scrolling to alter value
            onWheel={(e: any) => {
                // Kilian : The any type here is to help the typeinference of `.blur()` and `.focus()`.
                // We need to find a way, because the code works. But the type inference doesn't.

                // Prevent the input value change
                e.target.blur();

                // Prevent the page/container scrolling
                e.stopPropagation();

                // Refocus immediately, on the next tick (after the current
                // function is done)
                setTimeout(() => {
                    e.target.focus();
                }, 0);
            }}
        />
    );

    return (
        <Stack direction="column" gap="8px">
            <Stack direction="row" alignItems="center" gap="12px">
                {property.hint && isSubproperty ?
                    <WhitePopover icon={roundInput} body={<FormPropertyHint hint={property.hint} />} size="260px" />
                :   roundInput}
                {unit && <Typography variant="body">{unit}</Typography>}
                {!property.readonly && defaultValue && (
                    <DefaultValueButton
                        onClick={() => {
                            if (previousValue === value && value === defaultValue) {
                                return;
                            }

                            setPreviousValue(defaultValue);
                            setValue(defaultValue);

                            updatePropertyValue(defaultValue);
                        }}
                    />
                )}
                {!!propertyScore?.score && isSubproperty && !isMultivalued && (
                    <FormUnit
                        value={Number((propertyVariation?.score ?? propertyScore?.score).toFixed(3))}
                        unit={scoreVariation?.unit_abbreviation ?? scoreVariation?.unit}
                    />
                )}
            </Stack>
            {defaultValue && (
                <Typography variant="caption" color={theme.custom.grey[1500]}>
                    {!property.readonly ?
                        t("Valeur par défaut :") + " " + formattedDefaultValue
                    :   t("Cette valeur est en lecture seule")}
                </Typography>
            )}
            {error && (
                <Typography variant="caption" color={theme.custom.red[600]}>
                    {(() => {
                        if (isValidNumber(property.minValue) && isValidNumber(property.maxValue)) {
                            return t("form.min_max.error", { min: property.minValue, max: property.maxValue });
                        }
                        if (isValidNumber(property.minValue)) {
                            return t("form.min.error", { min: property.minValue });
                        }
                        if (isValidNumber(property.maxValue)) {
                            return t("form.max.error", { max: property.maxValue });
                        }
                        return null;
                    })()}
                </Typography>
            )}
        </Stack>
    );
};
