import { Stack, StackProps, styled, Typography } from "@mui/material";
import { TypographyOwnProps } from "@mui/material/Typography/Typography";
import { ScatterScoreChangeIndicator } from "./ScatterScoreChangeIndicator";
import { formatNumber } from "src/utils/formatting";
import { useTranslation } from "react-i18next";
import EmptySetIcon from "src/assets/icons/empty-set.svg?react";
import { EmptyScore, isScoreEmpty, Score } from "src/api/client-api/post/scoreSchema";

export type ScoreValueProps = StackProps & {
    variant?: TypographyOwnProps["variant"];
    score: Score | EmptyScore;
    align?: "left" | "center" | "right";
    difference?: number;
};

export const UnitStyled = styled(Typography)(({ theme }) => ({
    wordSpacing: "0.2rem",
    "> span:first-of-type": {
        fontSize: "12px",
    },
    "> span": {
        fontSize: theme.typography.subtitle2,
        color: "#A3A2A0",
    },
    "> strong": {
        fontSize: "14px",
        textDecoration: "none",
        fontWeight: 800,
    },
}));

export const ScatterScoreValue = (props: ScoreValueProps) => {
    const { i18n } = useTranslation();
    const { score: value, align = "left", ...stackProps } = props;

    const scoreExists = !isScoreEmpty(value);

    return (
        <Stack
            direction="column"
            flexBasis="100%"
            alignItems={
                {
                    left: "flex-start",
                    center: "center",
                    right: "flex-end",
                }[align]
            }
            {...stackProps}
        >
            <Stack alignItems="center" gap="12px">
                <Stack alignItems="center" gap="10px">
                    <Typography
                        variant={props.variant}
                        sx={{
                            fontWeight: 800,
                            position: "relative",
                        }}
                    >
                        {scoreExists ? formatNumber(value.score, i18n.resolvedLanguage) : <EmptySetIcon />}
                    </Typography>
                </Stack>
                <ScatterScoreChangeIndicator value={props.difference} />
            </Stack>

            <UnitStyled
                variant="body"
                sx={{
                    position: "relative",
                    top: "-6px",
                }}
            >
                {scoreExists && value.unit}
            </UnitStyled>
        </Stack>
    );
};
