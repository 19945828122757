import { Stack, Typography } from "@mui/material";
import { FormProperty, FormPropertyProps } from "../../FormProperty";
import { PostElement } from "src/api/client-api/post/postSchema";
import { Property } from "src/api/sure-api/ssq/useGetOneSsq";
import { PostElementWrite } from "src/api/client-api/property/useUpdateProperty";

export const GroupSimple = (props: FormPropertyProps) => {
    const { property, post } = props;

    const childProperties = (property.children ?? [])
        .filter((property) => !property.hidden && !post?.hiddenElements?.includes(`property-${property.id}`))
        .sort((propertyA: Property, propertyB: Property) => propertyA.position - propertyB.position);
    return (
        <Stack direction="column" gap="24px">
            {childProperties.map((childProperty: Property, index: number) => (
                <Stack key={index} direction="column" gap="8px">
                    <Typography variant="h5">{childProperty.title} :</Typography>

                    <FormProperty
                        {...props}
                        property={childProperty}
                        element={post?.elements?.find((element: PostElement | PostElementWrite) => {
                            return childProperty.id === element.ssqPropertyId;
                        })}
                        isSubproperty={true}
                    />
                </Stack>
            ))}
        </Stack>
    );
};
