import z from "zod";
import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { IRI } from "src/types";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { Scores, ScoresSchema } from "../post/scoreSchema";
import { getDimensionsQueryParam, ScoreEntityEndpointOptions } from "src/api/apiHelpers";

type Options = ScoreEntityEndpointOptions & {
    enabled?: boolean;
};
export const useGetOneProduct = (productId: string, options?: Options) => {
    const { variations, dimensions = [["main"]], campaign, enabled = true } = options || {};

    const query = useQuery({
        queryKey: ["getProduct", productId, variations, campaign],
        cacheTime: 0,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        enabled: enabled,
        queryFn: async () => {
            const url = `/api/products/${productId}`;

            const response = await loggedInApiCall.get<Product>(url, {
                params: {
                    ...options,
                    dimensions: getDimensionsQueryParam(dimensions),
                },
            });
            return response;
        },
        select: (request) => {
            const product = request.data;
            const data = zodParseWithLog(product, ProductSchema, "oneProduct");
            return data;
        },
    });

    return {
        product: query.data,
        ...query,
    };
};

// INGREDIENTS
type IngredientLine = {
    "@id": string;
    id: number;
    name: string;
};
const IngredientLineZodSchema: z.ZodSchema<IngredientLine> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
});
type SupplierFromIngSupplier = {
    "@id": string;
    id: number;
    name: string;
    clientId?: string;
};
const SupplierFromIngSupplierSchema: z.ZodSchema<SupplierFromIngSupplier> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
    clientId: z.string().optional(),
});
type IngredientSupplier = {
    "@id": string;
    id: number;
    supplier: SupplierFromIngSupplier;
    quantity?: number;
    unit?: string;
};
const IngredientSupplierSchema: z.ZodSchema<IngredientSupplier> = z.object({
    "@id": z.string(),
    id: z.number(),
    supplier: SupplierFromIngSupplierSchema,
    quantity: z.number().optional(),
    unit: z.string().optional(),
});
export type Ingredient = {
    "@id": string;
    id: number;
    name: string;
    externalId?: number;
    ingredientSuppliers?: IngredientSupplier[];
    ingredientReferenceId?: number;
    ingredientLine?: IngredientLine;
    organic?: boolean;
    origin?: string;
};
export const IngredientZodSchema: z.ZodSchema<Ingredient> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
    externalId: z.number().optional(),
    ingredientSuppliers: IngredientSupplierSchema.array().optional(),
    ingredientLine: IngredientLineZodSchema.optional(),
    ingredientReferenceId: z.number().optional(),
    organic: z.boolean().optional(),
    origin: z.string().optional(),
});

export type ProductIngredient = {
    "@id": string;
    id: number;
    proportion?: number;
    brand?: string;
    organic?: boolean;
    quantity?: number;
    usedQuantity?: number;
    usedProportion?: number;
    isUsedQuantityEqualToQuantity: boolean;
    ciqualFoodId?: number;
    origin?: string;
    category?: string;
    agriculturalProductionDiversity?: number;
    agroEcologicalInfrastructure?: number;
    farmingConditionsQuality?: number;
    agribalyseOrganic?: number;
    agribalyseConventional?: number;
    ingredient: Ingredient;
};

export const ProductIngredientSchema: z.ZodSchema<ProductIngredient> = z.object({
    "@id": z.string(),
    id: z.number(),
    proportion: z.number().optional(),
    quantity: z.number().optional(),
    usedQuantity: z.number().optional(),
    usedProportion: z.number().optional(),
    isUsedQuantityEqualToQuantity: z.boolean(),
    organic: z.boolean().optional(),
    ciqual_food_id: z.number().optional(),
    origin: z.string().optional(),
    category: z.string().optional(),
    agricultural_production_diversity: z.number().optional(),
    agro_ecological_infrastructure: z.number().optional(),
    farming_conditions_quality: z.number().optional(),
    agribalyse_organic: z.number().optional(),
    agribalyse_conventional: z.number().optional(),
    ingredient: IngredientZodSchema,
});

export type Brand = {
    "@id": IRI;
    id: number;
    name: string;
    logoUrl?: string | null;
};

export const BrandSchema: z.ZodSchema<Brand> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
    logoUrl: z.string().optional(),
});

export type ProductLine = {
    "@id": IRI;
    id: number;
    name: string;
};

export const ProductLineZodSchema: z.ZodSchema<ProductLine> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
});

export type Tag = {
    "@id": IRI;
    id: number;
    name: string;
};

export const TagSchema: z.ZodSchema<Tag> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
});

export type MaterialInPackaging = {
    "@id"?: IRI;
    id?: number;
    packagingMaterialSetId: number;
    weight?: number;
    proportion?: number; // not present in backend
};
const MaterialSchema: z.ZodSchema<MaterialInPackaging> = z.object({
    "@id": z.string(),
    id: z.number(),
    packagingMaterialSetId: z.number(),
    proportion: z.number().optional(),
    weight: z.number().optional(),
});

export type ProductPackaging = {
    "@id": IRI;
    id: number;
    packagingFormId: number;
    packagingMaterials: MaterialInPackaging[];
};
const PackagingSchema: z.ZodSchema<ProductPackaging> = z.object({
    "@id": z.string(),
    id: z.number(),
    packagingFormId: z.number(),
    packagingMaterials: MaterialSchema.array(),
});

export type ProductTransformation = {
    "@id": IRI;
    id: number;
    productTransformationProcessId: number;
    weight?: number;
};

const ProductTransformationSchema: z.ZodSchema<ProductTransformation> = z.object({
    "@id": z.string(),
    id: z.number(),
    productTransformationProcessId: z.number(),
    weight: z.number().optional(),
});

export type Product = {
    "@id": string;
    id: number;
    title: string;
    clientId?: string;
    brand?: Brand;
    productReferenceId?: number;
    netWeight?: number;
    productLine?: ProductLine;
    tags?: Tag[];
    productIngredients?: ProductIngredient[];
    productPackagings?: ProductPackaging[];
    productTransformations?: ProductTransformation[];
    consumptionsIds?: number[];
    distributionChannelId?: number;
    ssqId?: number;
    canModify: boolean;
    canDelete: boolean;
    scores: Scores;
    prevScores?: Scores;
    prevCampaignName?: string;
};

export const ProductSchema: z.ZodSchema<Product> = z.object({
    "@id": z.string(),
    id: z.number(),
    title: z.string(),
    clientId: z.string().optional(),
    brand: BrandSchema.optional(),
    productReferenceId: z.number().optional(),
    netWeight: z.number().optional(),
    productLine: ProductLineZodSchema.optional(),
    tags: TagSchema.array().optional(),
    productIngredients: ProductIngredientSchema.array().optional(),
    productPackagings: PackagingSchema.array().optional(),
    productTransformations: ProductTransformationSchema.array().optional(),
    consumptionsIds: z.number().array().optional(),
    ssqId: z.number().optional(),
    distributionChannelId: z.number().optional(),
    canModify: z.boolean(),
    canDelete: z.boolean(),
    scores: ScoresSchema,
    prevScores: ScoresSchema.optional(),
    prevCampaignName: z.string().optional(),
});
