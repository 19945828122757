import {
    Button,
    ContextualContentBox,
    FormBox,
    HelperBox,
    TextField,
    Typography,
    useFormBaseContext,
} from "@foodpilot/foods";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IngredientReferenceField } from "./IngredientReferenceField";
import { FilliereField } from "./FilliereField";
import { RadioBooleanField } from "src/components/Radio/RadioBooleanField";
import { IngredientFormIngredientLine, IngredientFormType } from "../../IngredientForm.types";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AgribalyseInfoDrawer } from "src/components/Drawer/AgribalyseInfo";

type Page1Props = {
    ingredient: IngredientFormType;
    setIngredient: (newIngredient: IngredientFormType) => void;
};
const Page1 = (props: Page1Props) => {
    return (
        <Box
            sx={{
                display: "flex",
                gap: "1rem",
            }}
        >
            <FormBox
                sx={{
                    flex: 3,
                }}
            >
                <BaseInformation {...props} />
            </FormBox>
            <FormBox
                sx={{
                    position: "sticky",
                    top: "120px",
                    flex: 1.5,
                    padding: 0,
                }}
            >
                <InformationPanel />
            </FormBox>
        </Box>
    );
};
export default Page1;

const BaseInformation = (props: Page1Props) => {
    const { ingredient, setIngredient } = props;
    const { t } = useTranslation();
    const { lockForward, unlockForward } = useFormBaseContext();

    const isNameValid = (name: string | null) => {
        return name && name.trim().length > 0;
    };
    const isClientIdValid = (clientId: string | null) => {
        return clientId && clientId.trim().length > 0;
    };
    const isIngredientReferenceIdValid = (ingredientReferenceId: number | null) => {
        return ingredientReferenceId && ingredientReferenceId > 0;
    };
    const isOrganicValid = (organic: boolean | null) => {
        return organic != null;
    };
    const isIngredientLineValid = (ingredientLine: IngredientFormIngredientLine | null) => {
        return ingredientLine != null;
    };

    useEffect(() => {
        const isPageError =
            !isNameValid(ingredient.name) ||
            !isClientIdValid(ingredient.clientId) ||
            !isIngredientReferenceIdValid(ingredient.ingredientReferenceId) ||
            !isOrganicValid(ingredient.organic) ||
            !isIngredientLineValid(ingredient.ingredientLine);

        if (isPageError) {
            const errorMessage = t("form.invalidStep");
            lockForward(errorMessage);
            return;
        }
        unlockForward();
    }, [
        ingredient.name,
        ingredient.clientId,
        ingredient.organic,
        ingredient.ingredientLine,
        ingredient.ingredientReferenceId,
    ]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "50px",
            }}
        >
            <TextField
                title={t("Nom de l'ingrédient")}
                required={true}
                error={!isNameValid(ingredient.name)}
                placeholder={""}
                value={ingredient.name ?? ""}
                onChange={(e) => {
                    const newName = e.target.value;
                    setIngredient({
                        ...ingredient,
                        name: newName,
                    });
                }}
            />
            <Box>
                <TextField
                    title={t("Identifiant unique")}
                    required={true}
                    error={!isClientIdValid(ingredient.clientId)}
                    placeholder={""}
                    value={ingredient.clientId ?? ""}
                    onChange={(e) => {
                        const newClientId = e.target.value;
                        setIngredient({
                            ...ingredient,
                            clientId: newClientId,
                        });
                    }}
                />
                <HelperBox>
                    <Typography variant="body">
                        {t(
                            "L'identifiant unique correspond à la manière dont vous marquer vos ingrédients dans votre entreprise",
                        )}
                    </Typography>
                </HelperBox>
            </Box>
            <IngredientReferenceField ingredient={ingredient} onChange={setIngredient} required={true} />
            <RadioBooleanField
                title={t("Ingrédient issue de l'agriculture biologique")}
                required={true}
                description={""}
                value={ingredient.organic ?? undefined} // Hotfix waiting for this element to accept null;
                onChange={(value) => {
                    setIngredient({
                        ...ingredient,
                        organic: value,
                    });
                }}
            />
            <FilliereField ingredient={ingredient} onChange={setIngredient} required={true} />
            <Box>
                <Typography variant="body">{t("form.mandatory-field")}</Typography>
            </Box>
        </div>
    );
};

const InformationPanel = () => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    return (
        <Box>
            <ContextualContentBox
                size="small"
                title={t("Pourquoi associer votre ingrédient à une référence Agribalyse® ?")}
            >
                <Box>
                    <Typography variant="body">{t("info.agribalyse-ingredient-1")}</Typography>
                    <Button variant="text" onClick={() => setOpen(true)} endIcon={<ArrowForwardIcon />}>
                        {t("En savoir plus")}
                    </Button>
                </Box>
            </ContextualContentBox>
            <AgribalyseInfoDrawer isOpen={open} setOpen={setOpen} />
        </Box>
    );
};
