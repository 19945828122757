import { FoodsIcon } from "@foodpilot/foods";
import { Stack, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Score } from "src/components/Score";
import {
    EmptyScore,
    Score as ScoreValue,
    ScoreDimensionBaseValue,
    isScoreEmpty,
} from "src/api/client-api/post/scoreSchema";

export const LinkStyled = styled("a")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2rem",
    background: "#FFF6E8",
    padding: ".5rem",
    fontWeight: 600,
    fontSize: "1rem",
    textDecoration: "none",
    color: theme.custom.grey[3000],
    width: "100%",
    borderRadius: theme.shape.borderRadius,
}));

export type ScatterScoreBoxCoreProps = {
    title: string;
    score: ScoreValue | EmptyScore;
    value?: ScoreDimensionBaseValue;
};
export const ScatterScoreBoxCorePopover = (props: ScatterScoreBoxCoreProps) => {
    const { title, score, value } = props;
    const { t } = useTranslation();

    const valueExists = value && !isScoreEmpty(score);

    return (
        <Stack sx={{ padding: "0px 8px 4px 8px", minWidth: "336px" }}>
            <Typography variant="h4" sx={{ marginBottom: "24px" }}>
                {title}
            </Typography>

            {valueExists && <Score value={value.score} unit={score.unit} />}

            <LinkStyled href="#">
                <FoodsIcon icon="clickIndicator" size={4} />
                <Typography variant="body-bold" sx={{ marginLeft: "0.5rem" }}>
                    {t("click_for_more_details")}
                </Typography>
            </LinkStyled>
        </Stack>
    );
};
