import { TertiaryToggleButtonNodeTitle } from "@foodpilot/foods";
import { Stack, Typography, useTheme, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Ingredient, IngredientSupplier } from "src/api/client-api/ingredients/useGetOneIngredient";
import { HintBox } from "src/components/HintBox";
import { NoReferenceIngredient } from "./NoReferenceIngredient";
import { SupplierScatterPlot } from "./MultipleSupplierScores/SuppliersScatterPlot";
import { formatNumber } from "src/utils/formatting.ts";
import { useFoodpilotContext } from "src/context/FoodpilotContext.tsx";
import { IngredientReference } from "src/api/sure-api/ingredientReference/useGetAllIngredientReference";
import EmptySetIcon from "src/assets/icons/empty-set.svg?react";
import { IngredientReferenceScores } from "src/api/client-api/ingredientReferenceScores/useGetIngredientReferenceScores.ts";
import { isScoreEmpty } from "src/api/client-api/post/scoreSchema";

export type VisualisationChoice = {
    key: "quantity" | "grossSales";
    unit: "T" | "€";
    label: string;
};

export type PartialScore = {
    title: string;
    unit: string;
};

export type ScoreType = {
    title: string;
    unit: string;
};

export type MultipleSupplierScoresProps = {
    suppliers: IngredientSupplier[] | undefined;
    ingredientReference: IngredientReference | undefined;
    ingredient: Ingredient;
    variations: Record<number, number>;
    setVariations: (variations: Record<number, number>) => void;
    isRefetching?: boolean;
    referenceScores: IngredientReferenceScores;
};
export const MultipleSupplierScores = (props: MultipleSupplierScoresProps) => {
    const { t } = useTranslation();
    const { getScoresByType } = useFoodpilotContext();
    const ingredientsScores = getScoresByType("ingredient");

    const [selectedScoreIndex, setSelectedScoreIndex] = useState(0);

    const { ingredient, ingredientReference, variations, setVariations, referenceScores, isRefetching = false } = props;

    if (!ingredientReference) return <NoReferenceIngredient ingredient={ingredient} />;

    const scoresChoices = ingredientsScores.flatMap((score, index) => {
        const ingredientScore = ingredient.scores[score.id];

        if (!ingredientScore || isScoreEmpty(ingredientScore)) {
            return (
                <ToggleButton
                    label={score.title}
                    unit={score.unit?.abbreviation}
                    key={index}
                    isRefetching={isRefetching}
                />
            );
        }

        return (
            <ToggleButton
                label={score.title}
                unit={score.unit?.abbreviation}
                value={ingredientScore.score}
                key={index}
                isRefetching={isRefetching}
            />
        );
    });

    const selectedScore = ingredientsScores[selectedScoreIndex];
    const areScoresMissing = ingredientsScores.find((score) => isScoreEmpty(ingredient.scores[score.id])) !== undefined;

    const mappedReferenceScores = Object.fromEntries(referenceScores.map((score) => [score?.score?.id, score]));
    const referenceScore = mappedReferenceScores[selectedScore.id] ?? null;

    return (
        <Stack gap="28px">
            {areScoresMissing && (
                <HintBox
                    title={t(
                        "Les scores & indicateurs de l’ingrédient ne peuvent pas être calculés car les questionnaires sont vides",
                    )}
                    content={t(
                        "Envoyer un rappel aux contributeurs des questionnaires pour obtenir les données nécessaires aux calculs de ces indicateurs",
                    )}
                />
            )}

            <TertiaryToggleButtonNodeTitle
                choices={scoresChoices}
                currentIndexSelected={selectedScoreIndex}
                onClick={setSelectedScoreIndex}
            />

            <SupplierScatterPlot
                score={selectedScore}
                ingredient={ingredient}
                suppliers={props.suppliers}
                variations={variations}
                setVariations={setVariations}
                isRefetching={isRefetching}
                referenceScore={referenceScore}
            />
        </Stack>
    );
};

const ToggleButton = ({
    label,
    value,
    unit,
    isRefetching = false,
}: {
    label: string;
    value?: number;
    unit?: string;
    isRefetching?: boolean;
}) => {
    const theme = useTheme();

    return (
        <Stack direction="row" alignItems="center" gap="12px">
            <Typography variant="body-medium">{label}</Typography>

            {!isRefetching ?
                <Stack direction="row" alignItems="center" gap="6px">
                    {value !== undefined ?
                        <Typography variant="huge-bold">{formatNumber(value)}</Typography>
                    :   <EmptySetIcon width="20px" height="20px" />}
                    <Typography variant="caption" style={{ color: theme.custom.grey[1400], lineHeight: "normal" }}>
                        {unit}
                    </Typography>
                </Stack>
            :   <CircularProgress size="18px" />}
        </Stack>
    );
};
