import { useMutation } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { z } from "zod";
import { PostSchema } from "src/api/client-api/post/postSchema";

export type UpdateProperty = {
    postId: number;
    propertyId: number;
    value: number | string;
    position?: number;
};
export const useUpdateProperty = () => {
    const query = useMutation({
        mutationFn: async (options: UpdateProperty) => {
            const { postId, propertyId, value, position = 0 } = options;

            return await loggedInApiCall.post(
                `/api/ssq_posts/${postId}/property/${propertyId}/calculate`,
                {
                    value: value,
                    position: position,
                },
                {
                    headers: {
                        Accept: "application/json",
                    },
                },
            );
        },
        onSuccess: (result) => {
            // Don't remove this log as it serves the product team
            console.log("Calculation result", result.data);
        },
    });

    return query;
};

export const PostElementWriteSchema = z.object({
    value: z.string(),
    position: z.number(),
    ssqPropertyId: z.number().optional(),
    name: z.string().optional(),
    unit: z.string().optional(),
    ssqHeadingId: z.number().optional(),
});

export type PostElementWrite = z.infer<typeof PostElementWriteSchema>;

export const PostWithWriteableElementsSchema = PostSchema.extend({
    elements: PostElementWriteSchema.array().optional(),
});

export type PostWithWriteableElements = z.infer<typeof PostWithWriteableElementsSchema>;
