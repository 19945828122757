import { useQuery } from "react-query";
import { sureApiCall } from "src/api/axiosClient/sureApi";
import { IngredientReferenceSchema } from "./useGetAllIngredientReference";
import { zodParseWithLog } from "src/api/apiErrorLogs";

type Options = {
    enabled: boolean;
};
export const useGetOneIngredientReference = (ingredientReferenceId: number, queryOptions?: Options) => {
    const { enabled = true } = queryOptions ?? {};
    const query = useQuery({
        retry: 0,
        enabled: enabled,
        queryKey: ["getIngredientReference", ingredientReferenceId],
        queryFn: async () => {
            const response = await sureApiCall.get(`/api/ingredient_references/${ingredientReferenceId}`);
            return response;
        },
        select: (request) => {
            const ingredientReference = request.data;
            const data = zodParseWithLog(ingredientReference, IngredientReferenceSchema, "oneIngredientRef");
            return data;
        },
    });

    return {
        ingredientReference: query.data,
        ...query,
    };
};
