import { FoodsBadge } from "@foodpilot/foods";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProductFormProductIngredient } from "src/pages/Product/ProductForm/ProductForm.types";
import { TextLink } from "src/components/Link/TextLink";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { Fragment } from "react/jsx-runtime";
import { URLHandler } from "src/services/URLHandler";

type LineContentProps = {
    ingredient: ProductFormProductIngredient;
};
export const LineContent = (props: LineContentProps) => {
    const { ingredient } = props;
    const { selectedCompany } = useFoodpilotContext();
    const theme = useTheme();
    const { t } = useTranslation();

    if (selectedCompany === null) return;
    return (
        <Fragment>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    marginBlock: "16px",
                }}
            >
                <FoodsBadge
                    icon="supplier"
                    size={1}
                    boxProps={{
                        sx: {
                            height: "28px",
                            width: "28px",
                            backgroundColor: theme.custom.grey[400],
                            borderRadius: "25px",
                        },
                    }}
                />
                <Typography variant="h5">{`${t("product.ingredient.related_suppliers")} :`}</Typography>
            </Box>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    rowGap: "8px",
                }}
            >
                {ingredient.ingredient.suppliersName.map((supplier) => {
                    return (
                        <TextLink
                            url={URLHandler.supplier.edit(selectedCompany.id, supplier.id)}
                            text={supplier.name}
                            color={theme.custom.grey[2800]}
                            typographyProps={{ variant: "small-medium" }}
                        />
                    );
                })}
            </Box>
        </Fragment>
    );
};
