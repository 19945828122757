import { Company } from "src/api/client-api/useUserNew";
import { useTranslation } from "react-i18next";
import { useGetOneSsq } from "src/api/sure-api/ssq/useGetOneSsq";
import { QuestionnaireUpdateFormBase } from "./QuestionnaireUpdateFormBase";
import { QuestionnaireUpdateFormSsq } from "./QuestionnaireUpdateFormSsq";

type QuestionnaireUpdateFormProps = {
    company: Company;
};
export const QuestionnaireUpdateForm = (props: QuestionnaireUpdateFormProps) => {
    const { company } = props;
    const { t } = useTranslation();

    const { ssq, isLoading } = useGetOneSsq(company.companySector?.ssqId ?? 0);

    if (isLoading) return <QuestionnaireUpdateFormBase company={company} isLoading={true} />;

    if (!ssq) return t("Le questionnaire pour ce diagnostic n'a pas été trouvé");

    return <QuestionnaireUpdateFormSsq company={company} ssq={ssq} />;
};
