import { Box, Typography } from "@mui/material";

type FormPropertyHintProps = {
    hint: string;
};

export const FormPropertyHint = (props: FormPropertyHintProps) => {
    const { hint } = props;

    return (
        <Box padding="12px 16px">
            <Typography variant="body">
                <Box dangerouslySetInnerHTML={{ __html: hint }} />
            </Typography>
        </Box>
    );
};
