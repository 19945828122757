import { Box, Typography, useTheme } from "@mui/material";
import { ComparisonIndicator } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";
import NoneIcon from "src/assets/icons/none.svg?react";
import { isScoreEmpty, Score } from "src/api/client-api/post/scoreSchema";

export type ScoreComparisonProps = {
    values: Score[];
};

export const ScatterScoreComparison = (props: ScoreComparisonProps): JSX.Element => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                background: theme.custom.grey[200],
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                gap: ".5rem",
                "> div:first-of-type": {
                    marginBottom: ".5rem",
                },
            }}
        >
            <Indicator
                value={props.values[0]}
                label={
                    <Box
                        sx={{
                            display: "flex",
                            gap: ".25rem",
                        }}
                    >
                        <Typography variant="big-medium">{t("par rapport à l'ingrédient de référence")}</Typography>
                    </Box>
                }
            />
            {props.values.length > 1 && (
                <Indicator
                    value={props.values[1]}
                    label={
                        <Box
                            sx={{
                                display: "flex",
                                gap: ".25rem",
                            }}
                        >
                            <Typography variant="big-medium">{t("par rapport à la")}</Typography>
                            <Typography variant="big-medium">{t("référence")}</Typography>
                        </Box>
                    }
                />
            )}
        </Box>
    );
};

type IndicatorProps = {
    value: Score;
    label: ReactNode;
    difference?: number;
};

const Indicator = (props: IndicatorProps) => {
    const { t } = useTranslation();
    const { value, label, difference } = props;

    if (isScoreEmpty(value)) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                }}
            >
                <Typography variant="body-medium">{t("Valeur de référence")}</Typography>
                {difference && <RefBox value={difference.toFixed(2)} />}
            </Box>
        );
    }

    const percentage = difference ? (difference / value.score) * 100 : 0;

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                gap: ".5rem",
                alignItems: "center",
                "> div:first-of-type": {
                    maxHeight: "32px",
                },
            }}
        >
            <ComparisonIndicator value={percentage} />
            {label}
            {difference && <DiffBox value={difference.toFixed(2)} />}
        </Box>
    );
};

type BoxProps = {
    value: string | undefined;
};

const DiffBox = (props: BoxProps) => {
    const { t } = useTranslation();
    const { value } = props;

    return (
        <Box
            style={{
                backgroundColor: "#FEFCFB",
                height: "fit-content",
                padding: ".15rem .75rem",
                display: "flex",
                gap: ".5rem",
                justifyContent: "center",
                alignItems: "center",
                width: "fit-content",
                borderRadius: "5px",
                border: "1px solid #E7E6E4",
            }}
        >
            <Typography variant="body">{t("soit")}</Typography>
            <Typography variant="big-bold" sx={{ fontWeight: 700 }}>
                {value}
            </Typography>
        </Box>
    );
};

const RefBox = (props: BoxProps) => {
    const { value } = props;

    return (
        <Box
            style={{
                backgroundColor: "#FEFCFB",
                height: "fit-content",
                padding: ".15rem .75rem",
                display: "flex",
                gap: ".5rem",
                justifyContent: "center",
                alignItems: "center",
                width: "fit-content",
                borderRadius: "5px",
                border: "1px solid #E7E6E4",
            }}
        >
            <Typography variant="big-bold">{value}</Typography>
        </Box>
    );
};

export const NoneBox = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
                width: "fit-content",
                padding: "1rem",
                borderRadius: "4px",
                bgcolor: theme.palette.grey[200],
            }}
        >
            <NoneIcon />
        </Box>
    );
};
