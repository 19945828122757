import { Stack, StackProps, Typography, useTheme } from "@mui/material";
import { ScoreExplicitValue } from "./ScoreExplicitValue";
import EmptySetIcon from "src/assets/icons/empty-set.svg?react";
import { Score as SureScore } from "src/types";
import { Score, EmptyScore, isScoreEmpty } from "src/api/client-api/post/scoreSchema";

export type ScoreValueProps = StackProps & {
    score: SureScore;
    value?: Score | EmptyScore;
    align?: "left" | "center" | "right";
};

export const ScoreValue = (props: ScoreValueProps) => {
    const theme = useTheme();
    const { score, value, align = "left", ...stackProps } = props;

    const scoreExists = value && !isScoreEmpty(value);
    const unit = scoreExists ? value.unit : score.unit?.abbreviation;

    return (
        <Stack
            direction="column"
            flexBasis="100%"
            alignItems={
                {
                    left: "flex-start",
                    center: "center",
                    right: "flex-end",
                }[align]
            }
            {...stackProps}
        >
            <Stack alignItems="center" gap="12px">
                {scoreExists ?
                    <ScoreExplicitValue score={value.score} precision={score.precision} />
                :   <EmptySetIcon />}
            </Stack>

            <Typography
                variant="body"
                sx={{
                    color: theme.custom.grey[1400],
                    position: "relative",
                    top: "-8px",
                }}
            >
                {unit}
            </Typography>
        </Stack>
    );
};
