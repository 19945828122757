import { z } from "zod";

const CompanySchema = z.object({
    id: z.number(),
    name: z.string(),
});

export type Company = z.infer<typeof CompanySchema>;

const MultivalueVariationSchema = z.object({
    score: z.number(),
});

const MultivalueSchema = z.object({
    score: z.number(),
    variations: z.record(z.number(), MultivalueVariationSchema).optional(),
});

const PostElementSchema = z.object({
    id: z.number(),
    value: z.string(),
    ssqPropertyId: z.number().optional(),
    position: z.number(),
    multivalues: z.record(z.number(), MultivalueSchema).optional(),
    updatedAt: z.string().optional(),
});

export type PostElement = z.infer<typeof PostElementSchema>;

// [ds] this schema should be removed after we move from formattedScores to the scores
export const FormattedScoresSchema = z.any();

export type FormattedScores = z.infer<typeof FormattedScoresSchema>;
// end of deprecated schema

export const PostSchema = z.object({
    id: z.number(),
    ssqId: z.number().optional(),
    elements: PostElementSchema.array().optional(),
    formattedScores: FormattedScoresSchema.optional(),
    hiddenElements: z.array(z.string()),
    company: CompanySchema.optional(),
    updatedAt: z.string(),
});

export type Post = z.infer<typeof PostSchema>;
