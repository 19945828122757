import { ChaptersScore, FormattedScore, FormattedScores, Score } from "src/types";
import { Post, PostElement } from "src/api/client-api/post/postSchema";
import { PostElementWrite } from "src/api/client-api/property/useUpdateProperty";
import { Heading, Ssq, Property } from "src/api/sure-api/ssq/useGetOneSsq";
import { ChapterName, isChapterName } from "src/types";

export const getVisibleHeadings = (ssq: Ssq) => {
    return ssq.headings
        .filter((heading: Heading) => heading.level === 1 && !heading.hidden)
        .sort((headingA: Heading, headingB: Heading) => headingA.position - headingB.position);
};

export const getVisibleProperties = (heading: Heading, hiddenElements: string[]) => {
    return heading.properties
        .filter((property: Property) => !property.hidden && !hiddenElements.includes(`property-${property.id}`))
        .sort((propertyA: Property, propertyB: Property) => propertyA.position - propertyB.position);
};

export const countElementsAndPropertiesRecursively = (
    properties: Property[],
    post: Post,
    input?: number[],
): number[] => {
    const visibleProperties = properties.filter(
        (property: Property) => !property.hidden && !post.hiddenElements.includes(`property-${property.id}`),
    );

    if (input) {
        input[1] += properties.length - 1;
    }

    return visibleProperties.reduce(
        (output: number[], property: Property) => {
            const ssqPostElement = post.elements?.find(
                (element: PostElement) =>
                    element.ssqPropertyId === property.id && element.value !== "" && element.value !== "_empty_",
            );

            if (ssqPostElement) {
                output[0]++;
            } else if (property.children?.length) {
                output = countElementsAndPropertiesRecursively(property.children, post, output);
            }

            return output;
        },
        input ?? [0, visibleProperties.length],
    );
};

export const getHeadingCompletion = (heading: Heading, post: Post): number => {
    let [elements, properties] = countElementsAndPropertiesRecursively(heading.properties, post);

    heading.children.forEach((child: Heading) => {
        const [childElements, childProperties] = countElementsAndPropertiesRecursively(child.properties, post);

        elements += childElements;
        properties += childProperties;
    });

    if (!properties) {
        return 0;
    }

    return Math.round((100 * elements) / properties);
};

export const getHeadingsCompletions = (headings: Heading[], post?: Post): number[] => {
    return headings.map((heading: Heading): number => {
        if (!post) {
            return 0;
        }

        return getHeadingCompletion(heading, post);
    });
};

export const getHeadingsDefaultElements = (headings: Heading[]): PostElementWrite[] => {
    const elements: PostElementWrite[] = [];

    headings.forEach((heading: Heading) => {
        elements.push(...getHeadingDefaultElements(heading));

        getHeadingsDefaultElements(heading.children);
    });

    return elements;
};

export const getHeadingDefaultElements = (heading: Heading): PostElementWrite[] => {
    const elements: PostElementWrite[] = [];

    heading.properties.forEach((property: Property) => {
        elements.push(...getPropertyDefaultElements(property, heading));
    });

    return elements;
};

export const getPropertyDefaultElements = (property: Property, heading: Heading): PostElementWrite[] => {
    const elements: PostElementWrite[] = [];

    if (property.defaultValue !== undefined && property.defaultValue !== "") {
        elements.push({
            ssqPropertyId: property.id,
            value: property.defaultValue,
            position: property.position,
            unit: property?.unit?.abbreviation,
            name: heading.title,
            ssqHeadingId: Number(heading.id),
        });
    }

    property.children?.forEach((subproperty: Property) => {
        elements.push(...getPropertyDefaultElements(subproperty, heading));
    });

    return elements;
};

export const getPostFormattedScores = (post?: Post): FormattedScores => {
    return post?.formattedScores ?? {};
};

export const getScoreVariations = (post: Post, scoreId?: number): FormattedScore["scoreVariations"] => {
    return getPostFormattedScores(post)[scoreId ?? 0]?.scoreVariations ?? {};
};

export const getFormattedScoreByScoreId = (
    formattedScores: FormattedScores,
    scoreId?: number,
): FormattedScore | undefined => {
    return Object.values(formattedScores).find((formattedScore) => formattedScore.chaptersScore?.score_id === scoreId);
};

export const getDefaultDisplayVariationId = (variations: FormattedScore["scoreVariations"]): number => {
    const variationsKeys = Object.keys(variations);
    const defaultDisplayVariationKey = variationsKeys.find((variationId) => variations[variationId].default_display);

    return Number(defaultDisplayVariationKey || variationsKeys[0]) || 0;
};

export const getScoresWithVariations = (formattedScores: FormattedScores): FormattedScores => {
    return Object.keys(formattedScores)
        .filter((scoreId: string) => formattedScores[scoreId].scoreVariations)
        .reduce((acc: FormattedScores, key) => {
            acc[key] = formattedScores[key];

            return acc;
        }, {});
};

export const getScoreVariationValue = (
    scoreWithVariations: ChaptersScore,
    score: Score,
    variationId?: number,
): number | undefined => {
    const displayMode = score.displayMode as ChapterName;

    if (!isChapterName(score.displayMode)) {
        return !variationId ? scoreWithVariations?.score : scoreWithVariations?.variations?.[variationId]?.score;
    }

    const chapter = scoreWithVariations.chapters?.[displayMode];

    // For chapters sure calculation returns chapter.result instead of chapter.score
    return chapter?.score ?? chapter?.result;
};
